import { logEvent } from '@amplitude/analytics-browser'
import {
  AppointmentBookAvailabilityClickEventProps,
  AppointmentBookAvailabilityShowEventProps,
  AppointmentBookSlotSelectedEventProps,
  AppointmentBookStartEventProps,
  AppointmentBookStateSelectedEventProps,
  AppointmentJoinStartEventProps,
  AppointmentModifyEventProps,
  NewOrExistingAppointmentEventProps,
} from './amplitude.types'

export class Amplitude {
  static userHasInsurance(cta: 'yes' | 'no', variant_label?: string) {
    logEvent('User Has Insurance', {
      cta,
      variant_label: variant_label ?? '',
    })
  }

  static insuranceEligibility(
    state_field: string,
    insurance_company: string,
    variant_label?: string
  ) {
    logEvent('Insurance Eligibility', {
      state_field,
      insurance_company,
      variant_label: variant_label ?? '',
    })
  }

  static insurancePlanEligibility(cta: 'yes' | 'no', variant_label?: string) {
    logEvent('User has non-commerce insurance', {
      cta,
      variant_label: variant_label ?? '',
    })
  }

  static insuranceCoverageLoaded(
    coverage: 'covered' | 'testing-covered' | 'more-info',
    variant_label?: string
  ) {
    logEvent('Insurance coverage Loaded', {
      coverage,
      variant_label: variant_label ?? '',
    })
  }

  static insuranceCoverage(
    cta: 'covered' | 'testing-covered',
    variant_label?: string
  ) {
    logEvent('Insurance coverage', {
      cta,
      variant_label: variant_label ?? '',
    })
  }

  static userSelectsProduct(cta: string, variant_label?: string) {
    logEvent('User Selects Product', {
      cta,
      variant_label: variant_label ?? '',
    })
  }

  static userViewsPaymentPlans(variant_label?: string) {
    logEvent('User Views Payment Plans', {
      variant_label: variant_label ?? '',
    })
  }

  static initiatesCheckout(
    cta: 'checkout' | 'consult',
    product_tier?: string,
    variant_label?: string
  ) {
    logEvent(
      'Initiates Checkout',
      product_tier
        ? {
            product_tier,
            cta,
            variant_label: variant_label ?? '',
          }
        : { cta, variant_label: variant_label ?? '' }
    )
  }

  static userEntersPayment(
    time_on_page_in_seconds: number,
    variant_label?: string
  ) {
    logEvent('User Enters Payment', {
      time_on_page_in_seconds,
      variant_label: variant_label ?? '',
    })
  }

  static howDidYouHear(
    choices: string[],
    cta: 'share' | 'skip',
    variant_label?: string
  ) {
    logEvent('How Did You Hear', {
      choices,
      cta,
      variant_label: variant_label ?? '',
    })
  }

  static consentToTelehealth(variant_label?: string) {
    logEvent('Consent To Telehealth', {
      variant_label: variant_label ?? '',
    })
  }

  static insuranceDetails(
    time_on_page_in_seconds: number,
    variant_label?: string
  ) {
    logEvent('Insurance Details', {
      cta: 'submit',
      time_on_page_in_seconds,
      variant_label: variant_label ?? '',
    })
  }

  static bookFirstAppointment(variant_label?: string) {
    logEvent('Book Appointment', {
      variant_label: variant_label ?? '',
    })
  }

  static loginOption(
    is_onboarding: boolean,
    choice_required?: 'email_or_phone' | 'otp_or_password',
    option_selected?:
      | 'otp_email'
      | 'otp_phone'
      | 'password'
      | 'email'
      | 'phone'
      | 'otp'
  ) {
    logEvent('LoginOptionSelected', {
      choice_required: choice_required ?? '',
      is_onboarding,
      option_selected: option_selected ?? '',
    })
  }

  static userLogsIn(loginOption: 'otp_email' | 'otp_phone' | 'password') {
    logEvent('User Logs In', {
      loginOption,
    })
  }

  static loginErrorShow(error: string) {
    logEvent('LoginErrorShow', {
      error,
    })
  }

  static appointmentBookStart(props: AppointmentBookStartEventProps) {
    logEvent('AppointmentBookStart', props)
  }

  static appointmentBookStateSelected(
    props: AppointmentBookStateSelectedEventProps
  ) {
    logEvent('AppointmentBookStateSelected', props)
  }

  static appointmentBookAvailabilityShow(
    props: AppointmentBookAvailabilityShowEventProps
  ) {
    logEvent('AppointmentBookAvailabilityShow', props)
  }

  static appointmentBookAvailabilityClick(
    props: AppointmentBookAvailabilityClickEventProps
  ) {
    logEvent('AppointmentBookAvailabilityClick', props)
  }

  static appointmentBookSlotSelected(
    props: AppointmentBookSlotSelectedEventProps
  ) {
    logEvent('AppointmentBookSlotSelected', props)
  }

  static appointmentBookDone(props: NewOrExistingAppointmentEventProps) {
    logEvent('AppointmentBookDone', props)
  }

  static appointmentModifyStart(props: AppointmentModifyEventProps) {
    logEvent('AppointmentModifyStart', props)
  }

  static appointmentCancelStart(props: AppointmentModifyEventProps) {
    logEvent('AppointmentCancelStart', props)
  }

  static appointmentCancelDone(props: AppointmentModifyEventProps) {
    logEvent('AppointmentCancelDone', props)
  }

  static appointmentRescheduleStart(props: AppointmentModifyEventProps) {
    logEvent('AppointmentRescheduleStart', props)
  }

  static appointmentJoinStart(props: AppointmentJoinStartEventProps) {
    logEvent('AppointmentJoinStart', props)
  }
}
