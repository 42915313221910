import { SecondaryPillButton } from 'components/Button'
import { useCallback, memo, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import OnboardingContainer from '../OnboardingContainer'
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg'
import { RoutePaths } from '../../Core/Routes'
import './styles.scss'
import useEligibilityInfo from 'hooks/useUser/useEligibilityInfo'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import useSearchParams from 'hooks/useSearchParams'
import UseOnboardingPageView from 'hooks/useOnboardingPageView'
import { Amplitude } from 'utils/amplitude.utils'

const HaveInsurance: React.FC = () => {
  const history = useHistory()
  const { setAlertText } = useAlert()
  const searchParams = useSearchParams()
  const { trackPageView } = UseOnboardingPageView()
  const {
    handleUpdateHaveInsurance,
    isLoading,
    waitlistUser: waitlistUserFromHook,
  } = useEligibilityInfo()
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<boolean | undefined>(undefined)
  const isRecheck = searchParams.get('insurance-recheck') !== null
  const variantLabelParam = searchParams.get('variant_label')

  const handleNo = useCallback(() => {
    handleSubmit(false)
  }, [])

  const handleYes = useCallback(() => handleSubmit(true), [])

  const handleSubmit = (response: boolean) => {
    Amplitude.userHasInsurance(response ? 'yes' : 'no', variantLabelParam ?? '')
    setLoading(true)
    setResponse(response)
    handleUpdateHaveInsurance({ response, recheck: isRecheck })
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setAlertText('Error checking for eligibility', 'An Error Occurred')
      })
  }

  // Track page view
  useEffect(() => {
    trackPageView('haveInsurance')
  }, [])

  useEffect(() => {
    //check if waitlistUserFromHook has been updated and navigate to the appropriate page
    if (!isLoading && !loading && response !== undefined) {
      const path = response
        ? RoutePaths.INSURANCE_ELIGIBILITY
        : RoutePaths.PROGRAMS
      history.push(`${path}${window.location.search}`)
    }
  }, [
    isLoading,
    loading,
    response,
    waitlistUserFromHook,
    window.location.search,
  ])

  return (
    <OnboardingContainer>
      <div className="d-flex justify-content-center">
        <div className="have-insurance mt-5 py-3 px-3 m-4 d-flex  align-items-center flex-column">
          <p className="header px-6">Do you have health insurance?</p>
          <SecondaryPillButton
            className="justify-content-between mt-3 w-100"
            onClick={handleYes}
          >
            <div>Yes</div>
            <RightIcon />
          </SecondaryPillButton>
          <SecondaryPillButton
            className="justify-content-between mt-3 w-100"
            onClick={handleNo}
          >
            <div>No</div>
            <RightIcon />
          </SecondaryPillButton>
        </div>
      </div>
    </OnboardingContainer>
  )
}

export default memo(HaveInsurance)
