import { useCallback } from 'react'
import { RoutePaths } from 'containers/Core/Routes'
import useUser from 'hooks/useUser/useUser'
import { useStytch } from '@stytch/react'
import { StytchAPIError } from '@stytch/vanilla-js'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const useLoginRedirect = () => {
  const { postLoginExecution } = useUser()
  const stytchClient = useStytch()
  const { smsOtpEnabled, emailOtpEnabled, passwordEnabled } = useFlags()

  const redirectUri =
    window.location.origin +
    (window.location.pathname !== '/login'
      ? window.location.pathname
      : RoutePaths.ONBOARDING) +
    window.location.search

  const checkPhoneVerification = useCallback(async () => {
    try {
      const user = await stytchClient.user.get()
      // returns true if the user has verified all emails
      // stytch does not allow a user to have more than one unverified device, so we need
      // to check if the user has verified all emails before adding the phone number
      const hasVerifiedAllEmails = user?.emails?.every(
        (email) => email.verified
      )

      if (
        user &&
        !user.phone_numbers?.length &&
        user.trusted_metadata?.phone_number &&
        smsOtpEnabled &&
        hasVerifiedAllEmails
      ) {
        // Store the intended redirect URL in session storage
        sessionStorage.setItem('postVerificationRedirect', redirectUri)
        return true
      }

      return false
    } catch (error) {
      if (error instanceof StytchAPIError) {
        console.error('Failed to fetch user details:', error)
      }

      return false
    }
  }, [stytchClient, redirectUri])

  const checkEmailVerification = useCallback(async () => {
    try {
      const user = await stytchClient.user.get()

      if (!user?.emails?.length && (emailOtpEnabled || passwordEnabled)) {
        // Store the intended redirect URL in session storage
        sessionStorage.setItem('postVerificationRedirect', redirectUri)
        return true
      }
    } catch (error) {
      if (error instanceof StytchAPIError) {
        console.error('Failed to fetch user details:', error)
      }
    }

    return false
  }, [stytchClient, redirectUri])

  const handleSuccessfulLogin = useCallback(async () => {
    const needsPhoneVerification = await checkPhoneVerification()
    const needsEmailVerification = await checkEmailVerification()

    if (needsPhoneVerification) {
      window.location.href = window.location.origin + RoutePaths.VERIFY_PHONE
    } else if (needsEmailVerification) {
      window.location.href = window.location.origin + RoutePaths.VERIFY_EMAIL
    } else {
      postLoginExecution()
      window.location.href = redirectUri
    }
  }, [postLoginExecution, redirectUri, checkPhoneVerification])

  return { handleSuccessfulLogin }
}
