/* eslint-disable react/no-children-prop */
import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  ChakraProvider,
  Flex,
  Center,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'
import { useStytch } from '@stytch/react'
import { StytchAPIError } from '@stytch/vanilla-js'
import { ddLog } from 'utils/logs.utils'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import validator from 'validator'
import useUser from 'hooks/useUser/useUser'

const VerifyPhone: React.FC = () => {
  const [methodId, setMethodId] = useState<string>('')
  const [showVerification, setShowVerification] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [areButtonsDisabled, setAreButtonsDisabled] = useState<boolean>(false)
  const stytchClient = useStytch()
  const toast = useToast()
  const { handleUpdateProfile } = useUser()

  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .min(10, 'Phone number must be 10 digits')
      .test('is-valid-phone', 'Must be a valid phone number', (value) =>
        validator.isMobilePhone(value || '')
      )
      .required('Phone number is required'),
    code: Yup.string().when('showVerification', {
      is: true,
      then: Yup.string().required('Verification code is required'),
    }),
  })

  const handleSkip = () => {
    setAreButtonsDisabled(true)

    try {
      const redirectUrl = sessionStorage.getItem('postVerificationRedirect')

      if (redirectUrl) {
        sessionStorage.removeItem('postVerificationRedirect')
        window.location.href = redirectUrl
      } else {
        window.location.href = window.location.origin
      }
    } catch (error) {
      // Fallback if sessionStorage is unavailable
      window.location.href = window.location.origin
    } finally {
      setAreButtonsDisabled(false)
    }
  }

  const sendPhoneOTP = useCallback(
    async (phoneNumberToVerify: string) => {
      setIsSubmitting(true)
      setAreButtonsDisabled(true)

      try {
        const response = await stytchClient.otps.sms.send(
          `+1${phoneNumberToVerify}`,
          {
            expiration_minutes: 5,
          }
        )
        setMethodId(response.method_id)
        setShowVerification(true)
      } catch (error) {
        if (error instanceof StytchAPIError) {
          let description = error.error_message

          if (error.error_type === 'invalid_phone_number') {
            description = 'Please enter a valid phone number'
          } else if (error.error_type === 'duplicate_phone_number') {
            description =
              'There was an error verifying your phone number. Please reach out to the concierge team from your dashboard for support.'
          }

          toast({
            title: 'Error sending code',
            description: description,
            status: 'error',
            duration: 5000,
          })
          ddLog('error', 'Stytch Error::phone OTP send error', {
            errorMessage: error.error_message,
            requestId: error.request_id,
          })
        }
      } finally {
        setIsSubmitting(false)
        setAreButtonsDisabled(false)
      }
    },
    [stytchClient, toast]
  )

  const verifyCode = useCallback(
    async (phoneNumber: string, code: string) => {
      setIsSubmitting(true)
      setAreButtonsDisabled(true)

      try {
        const response = await stytchClient.otps.authenticate(code, methodId, {
          session_duration_minutes: 60,
        })

        if (response.status_code === 200) {
          try {
            await handleUpdateProfile({
              phoneNumber,
            })
          } catch (error) {
            toast({
              title: 'Error updating profile',
              description:
                'Your phone was verified but we could not update your profile. Please try again later.',
              status: 'error',
              duration: 5000,
            })
          } finally {
            const redirectUrl = sessionStorage.getItem(
              'postVerificationRedirect'
            )
            window.location.href = redirectUrl || window.location.origin
            sessionStorage.removeItem('postVerificationRedirect')
          }
        }
      } catch (error) {
        if (error instanceof StytchAPIError) {
          let description = error.error_message

          if (
            error.error_type === 'otp_code_not_found' ||
            error.error_type === 'unable_to_auth_otp_code'
          ) {
            description =
              'The code you entered is invalid. Please try again or resend yourself a code.'
          }

          toast({
            title: 'Error verifying code',
            description,
            status: 'error',
            duration: 5000,
          })
          ddLog('error', 'Stytch Error::phone OTP verify error', {
            errorMessage: error.error_message,
            requestId: error.request_id,
          })
        }
      } finally {
        setIsSubmitting(false)
        setAreButtonsDisabled(false)
      }
    },
    [methodId, stytchClient, toast]
  )

  return (
    <ChakraProvider>
      <Flex
        justify="center"
        align="center"
        height="100vh"
        backgroundColor="var(--gray-extra-light)"
      >
        <Box bg="white" p={8} borderRadius="lg" boxShadow="lg" width="md">
          <Formik
            initialValues={{ phoneNumber: '', code: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (!showVerification) {
                sendPhoneOTP(values.phoneNumber)
              } else {
                verifyCode(values.phoneNumber, values.code)
              }
            }}
          >
            {({ isValid, dirty, handleChange, values }) => (
              <Form>
                <Box>
                  <Text color="gray.700" as="h5" size="lg" mb={4}>
                    {showVerification ? 'Log in' : 'Verify phone number'}
                  </Text>

                  {showVerification ? (
                    <>
                      <Text mb={4}>
                        Please enter the code we sent to {values.phoneNumber} to
                        finish logging into your account.
                      </Text>
                      <FormControl mb={4}>
                        <FormLabel>Verification Code</FormLabel>
                        <Field
                          as={Input}
                          name="code"
                          value={values.code}
                          placeholder="Enter code"
                        />
                        <ErrorMessage
                          name="code"
                          component={FormErrorMessage}
                        />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <Text color="gray.500" size="sm" mb={3}>
                        We&apos;ll send you a one-time code to verify your phone
                        number for next time.
                      </Text>
                      <FormControl mb={4} isRequired>
                        <FormLabel htmlFor="phoneNumber">
                          Mobile phone number
                        </FormLabel>
                        <InputGroup>
                          <InputLeftAddon children="+1" />
                          <Field
                            as={Input}
                            id="phoneNumber"
                            name="phoneNumber"
                            type="tel"
                            maxLength={10}
                            pattern="[0-9]*"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              e.target.value = e.target.value.replace(
                                /[^\d]/g,
                                ''
                              )
                              handleChange(e)
                            }}
                            placeholder="Enter your phone number"
                            borderLeftRadius={0}
                          />
                        </InputGroup>
                        <ErrorMessage
                          name="phoneNumber"
                          component={FormErrorMessage}
                        />
                      </FormControl>
                    </>
                  )}

                  <Button
                    type="submit"
                    backgroundColor={'var(--blue-dark)'}
                    color="white"
                    width="full"
                    isDisabled={!(isValid && dirty) || areButtonsDisabled}
                    isLoading={isSubmitting}
                    mt={6}
                    _hover={{
                      backgroundColor: 'var(--blue-dark-hover)',
                    }}
                    mb={4}
                  >
                    {showVerification ? 'Log in' : 'Next'}
                  </Button>

                  {showVerification ? (
                    <>
                      <Button
                        variant="outline"
                        onClick={handleSkip}
                        width="full"
                        mb={4}
                        isDisabled={areButtonsDisabled}
                      >
                        Skip verification
                      </Button>
                      <Center>
                        <Button
                          colorScheme={'var(--blue-dark)'}
                          variant="link"
                          width="full"
                          onClick={() => {
                            sendPhoneOTP(values.phoneNumber)
                            toast({
                              title: 'Code resent',
                              description:
                                'A new code has been sent to your phone number',
                              status: 'success',
                              duration: 10000,
                            })
                          }}
                          isDisabled={areButtonsDisabled}
                        >
                          Resend code
                        </Button>
                      </Center>
                    </>
                  ) : (
                    <Center>
                      <Button
                        variant="link"
                        onClick={handleSkip}
                        isDisabled={areButtonsDisabled}
                      >
                        Skip
                      </Button>
                    </Center>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>
    </ChakraProvider>
  )
}

export default VerifyPhone
