import React from 'react'
import { Box, Button } from '@chakra-ui/react'
import TitleWithBack from 'components/TitleWithBack'
import { Amplitude } from 'utils/amplitude.utils'

interface SignupMethodChoiceProps {
  email: string
  phoneNumber: string
  onPhoneChoice: () => void
  onEmailChoice: () => void
  onBack: () => void
  showEmailOption: boolean
}

const SignupMethodChoice: React.FC<SignupMethodChoiceProps> = ({
  onPhoneChoice,
  onEmailChoice,
  onBack,
  showEmailOption,
}) => {
  return (
    <Box>
      <TitleWithBack
        title="Create your account"
        subtitle="Choose how you'd like to create your account."
        onBack={onBack}
      />
      <Button
        onClick={() => {
          Amplitude.loginOption(true, 'email_or_phone', 'phone')
          onPhoneChoice()
        }}
        backgroundColor={'var(--blue-dark)'}
        color="white"
        width="full"
        mb={showEmailOption ? 4 : 0}
        _hover={{
          backgroundColor: 'var(--blue-dark-hover)',
        }}
      >
        Continue with phone number
      </Button>

      {showEmailOption && (
        <Button
          onClick={() => {
            Amplitude.loginOption(true, 'email_or_phone', 'email')
            onEmailChoice()
          }}
          width="full"
          variant="link"
          color={'var(--blue-dark)'}
        >
          Continue with email
        </Button>
      )}
    </Box>
  )
}

export default SignupMethodChoice
