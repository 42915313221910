import { WithAccountProps } from 'hoc/withAccount'
import useActions, {
  Action,
  ActionDefinition,
} from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import {
  formatActionText,
  getActionLink,
  getDisableButton,
  getDisplayButton,
  getTooltip,
  handleAction,
} from 'utils/actions.utils'
import DashboardCard from './Card'
import CardForm from './CardForm'
import ContentCard from './ContentCard'
import {
  AppointmentJoinStartEventProps,
  BookingEventSource,
} from 'utils/amplitude.types'
import { Appointment } from 'hooks/useAppointments/useAppointments'
import { Amplitude } from 'utils/amplitude.utils'
import { useSourceContext } from 'context/SourceContext/SourceProvider'

interface ActionCardProps extends WithAccountProps {
  action: Action
  completed?: boolean
  actionDefinition: ActionDefinition
  appointments?: Appointment[]
}

const ActionCard: React.FC<ActionCardProps> = ({
  action,
  completed,
  actionDefinition,
  appointments = [],
  user,
}) => {
  const { handleCompleteAction } = useActions()
  const { source } = useSourceContext()

  const FORM_DEFINITIONS_TO_ALWAYS_SHOW = ['download-app']
  const disableButton = getDisableButton(action, actionDefinition, completed)
  const displayButton = getDisplayButton(
    disableButton,
    actionDefinition,
    completed
  )
  const buttonTooltip = getTooltip(action, actionDefinition)
  const buttonLink = disableButton
    ? undefined
    : getActionLink(action, actionDefinition, user, completed)
  const handleClick = useCallback(async () => {
    const buttonLink = actionDefinition.buttonLink
    const autoComplete =
      action.completeCondition?.code === 'auto-complete' ||
      !action.completeCondition

    // this is handling so we can track the join now action
    if (actionDefinition.type === 'join-now') {
      const internalAppointmentId = action.properties?.appointmentId
      const appointment = appointments.find(
        (appointment) => appointment.id === internalAppointmentId
      ) as Appointment

      try {
        const appointmentType = await source.scheduling.appointmentTypes.retrieve(
          appointment?.type.id
        )
        const amplitudeEventProps: AppointmentJoinStartEventProps = {
          appointment_id: appointment?.sourceId,
          appointment_type_name: appointmentType?.name ?? '',
          appointment_time: appointment?.time ?? '',
          patient_action_id: action.id,
          patient_action_definition: action.definition,
          source: BookingEventSource.DASHBOARD_ACTION,
        }
        Amplitude.appointmentJoinStart(amplitudeEventProps)
      } catch (error) {
        console.error('Error retrieving appointment type', error)
      }
    }

    if (
      (buttonLink && autoComplete) ||
      actionDefinition.content ||
      autoComplete
    ) {
      handleCompleteAction(action.id)
    } else {
      handleAction(action, handleCompleteAction)
    }
  }, [action, actionDefinition])

  return (
    <DashboardCard
      icon={actionDefinition.icon}
      title={formatActionText(actionDefinition.title, user, action)}
      description={formatActionText(
        completed
          ? actionDefinition.completedDescription
          : actionDefinition.description,
        user,
        action
      )}
      buttonLink={buttonLink}
      buttonText={actionDefinition.buttonText}
      buttonTooltip={buttonTooltip}
      displayButton={displayButton}
      disableButton={disableButton}
      handleClick={handleClick}
    >
      {actionDefinition.type === 'form' &&
        (!completed ||
          FORM_DEFINITIONS_TO_ALWAYS_SHOW.includes(action.definition)) && (
          <CardForm action={action} actionDefinition={actionDefinition} />
        )}
      {actionDefinition.type === 'content' && (
        <ContentCard action={action} actionDefinition={actionDefinition} />
      )}
    </DashboardCard>
  )
}

export default ActionCard
