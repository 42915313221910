import { Thread as SourceThread } from '@allara-health/source-health-client'
import { ChakraPrimaryButton, SecondaryButton } from 'components/Button'
import useThreads from 'hooks/useThreads/useThreads'
import { memo, useCallback, useEffect, useState } from 'react'
import ThreadDescription from './ThreadDescription'
import useThread from 'hooks/useThreads/useThread'
import { useQueryClient } from 'react-query'
import { ThreadsQueryKey } from 'api/source/routes'
import useUser from 'hooks/useUser/useUser'
import { Banner } from 'components/Banners'
import useBanner from 'hooks/useBanner/useBanner'
import BlockContent from '@sanity/block-content-to-react'
import { InfoIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/react'
import './styles.scss'
import { BannerConstants } from '../../../hooks/useBanner/useBanner'
import MessageInput from './MessageInput'
import MessageList from './MessageList'
import { Thread as ThreadProvider } from '../../../context/Threads/ThreadProvider'
import CustomMessage from './CustomMessage'

interface ThreadProps {
  thread: SourceThread
}

const Thread: React.FC<ThreadProps> = ({ thread }) => {
  const { user } = useUser()
  const { markAsRead } = useThreads()
  const queryClient = useQueryClient()
  const { reopenThread } = useThread()
  const { banner } = useBanner(BannerConstants.MESSAGING)
  const [isReopening, setIsReopening] = useState<boolean>(false)

  const handleReopenThreadStatus = useCallback(() => {
    setIsReopening(true)

    reopenThread().finally(() => setIsReopening(false))
  }, [thread])

  const onSendMessage = useCallback(() => {
    queryClient.invalidateQueries(ThreadsQueryKey)
  }, [queryClient])

  useEffect(() => {
    if (thread) {
      markAsRead(thread)
    }
  }, [thread])

  return (
    <>
      <div className="d-none d-lg-flex py-3 justify-content-between align-items-center thread-details-container">
        <ThreadDescription thread={thread} />
        {thread.status === 'closed' && user?.messagingDeactivated === false && (
          <ChakraPrimaryButton
            variant="outline"
            backgroundColor="unset"
            color="var(--blue-dark)"
            _hover={{
              backgroundColor: 'var(--blue-dark)',
              cursor: 'pointer',
              color: 'white',
            }}
            onClick={handleReopenThreadStatus}
            disabled={isReopening}
            isLoading={isReopening}
          >
            Reopen
          </ChakraPrimaryButton>
        )}
      </div>
      <div
        className="d-flex flex-column messaging-container"
        style={{ gap: '2rem', paddingTop: '.5rem' }}
      >
        <ThreadProvider id={thread.id} onSend={onSendMessage}>
          {thread.status === 'closed' && user?.messagingDeactivated === false && (
            <div className="d-block d-lg-none">
              <ChakraPrimaryButton
                variant="outline"
                backgroundColor="unset"
                color="var(--blue-dark)"
                _hover={{
                  backgroundColor: 'var(--blue-dark)',
                  cursor: 'pointer',
                  color: 'white',
                }}
                onClick={handleReopenThreadStatus}
                disabled={isReopening}
                isLoading={isReopening}
              >
                Reopen
              </ChakraPrimaryButton>
            </div>
          )}
          {<MessageList MessageComponent={CustomMessage} />}
          <div>
            <Box
              color="gray"
              fontSize="sm"
              display="flex"
              alignItems="center"
              paddingBottom="1rem"
            >
              <InfoIcon marginRight=".5rem" marginLeft=".75rem" />
              Please note: This is not a live chat. You can expect a reply
              within 48 hours. If you are experiencing a medical emergency,
              please dial 911.
            </Box>
            {thread.status !== 'closed' &&
              user?.messagingDeactivated === false && (
                <MessageInput thread={thread} />
              )}
            <Banner
              visible={banner?.bannerStatus ?? false}
              variant="warning"
              message={
                <BlockContent
                  // Array of portable text blocks
                  blocks={banner?.description}
                  // Custom serializers for marks, blocks
                  serializers={{
                    marks: (props: {
                      children: React.ReactNode | string | undefined
                    }) => {
                      return <span>{props.children}</span>
                    },
                  }}
                />
              }
            />
          </div>
        </ThreadProvider>
      </div>
    </>
  )
}

export default memo(Thread)
