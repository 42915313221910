import withAccount, { WithAccountProps } from 'hoc/withAccount'
import { isInsurancePatient } from 'utils/program.utils'
import './styles.scss'

const LegalText: React.FC<WithAccountProps> = ({ user }) => {
  return (
    <div className="legal-text">
      {isInsurancePatient(user) && (
        <>
          <p className="fs-2 text-uppercase mb-0">Billing Info:</p>
          <p className="fs-2">
            You will be responsible for any co-pays, co-insurance, or
            deductibles assigned by your insurance, if any. We will use the
            credit/debit card on file to charge you once your insurance
            processes the claim, which typically happens 1-3 weeks after your
            appointment. We know that not all insurances cover nutrition
            benefits, so if your insurance does not cover nutrition services, we
            have a discounted nutrition visit cash pay price of $50 per visit.
            We will attempt to bill for these visits before we charge you.
          </p>
          <p className="fs-2">
            If you have an outstanding balance future appointments may be
            cancelled/blocked from booking until balances are settled.
          </p>
        </>
      )}
      <p className="fs-2 text-uppercase mb-0">Cancellation Policy:</p>
      <p className="fs-2">
        To respect our provider&apos;s time, all appointments must be cancelled
        at least 24 hours in advance. Your provider will wait 5 minutes past the
        start of your appointment before you&apos;re marked as a no-show.
        Violations of this policy will result in a $50 charge. Thank you for
        understanding.
      </p>
    </div>
  )
}

export default withAccount(LegalText)
