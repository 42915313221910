import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import { useStytch } from '@stytch/react'
import { StytchAPIError } from '@stytch/vanilla-js'
import { ddLog } from 'utils/logs.utils'
import { useLoginRedirect } from 'hooks/useUser/useLoginRedirect'
import TitleWithBack from 'components/TitleWithBack'
import { Amplitude } from 'utils/amplitude.utils'

interface FormData {
  password: string
}

interface PasswordLoginProps {
  initialEmail: string
  onBack?: () => void
}

const PasswordLogin: React.FC<PasswordLoginProps> = ({
  initialEmail,
  onBack,
}) => {
  const stytchClient = useStytch()
  const toast = useToast()
  const { handleSuccessfulLogin } = useLoginRedirect()

  const initialValues: FormData = {
    password: '',
  }

  const authenticatePassword = useCallback(
    async (values: FormData) => {
      try {
        const response = await stytchClient.passwords.authenticate({
          email: initialEmail,
          password: values.password,
          session_duration_minutes: 60,
        })

        if (response.status_code === 200) {
          Amplitude.userLogsIn('password')
          handleSuccessfulLogin()
        }
      } catch (error) {
        let title = 'Invalid email or password'
        let description = ''

        if (error instanceof StytchAPIError) {
          if (error.error_type === 'reset_password') {
            title = 'Reset your password'
            description = `Please reset your password by clicking Forgot password.`
            ddLog('info', 'Stytch Error::login password reset error', {
              errorMessage: error.error_message,
              requestId: error.request_id,
            })
          } else {
            title = 'Error signing in'
            description = `Incorrect email or password.`
            ddLog('info', 'Stytch Error::login error', {
              errorMessage: error.error_message,
              requestId: error.request_id,
            })
          }
        }

        toast({
          title: title,
          description: description,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
    [stytchClient, handleSuccessfulLogin, toast]
  )

  const resetPasswordStart = useCallback(
    async (email: string) => {
      if (!email) {
        toast({
          title: 'Please enter an email address',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        return
      }

      toast({
        title:
          'If an account exists with that email address, a password reset link will be sent.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })

      try {
        await stytchClient.passwords.resetByEmailStart({
          reset_password_template_id: 'password_reset',
          email: email,
        })
      } catch (e) {
        if (e instanceof StytchAPIError) {
          ddLog('info', 'Stytch Error::password reset error', {
            errorMessage: e.error_message,
            requestId: e.request_id,
          })
        }
      }
    },
    [stytchClient, toast]
  )

  return (
    <Box>
      <TitleWithBack
        title="Log in with password"
        subtitle={`Enter the password for ${initialEmail}`}
        onBack={onBack}
      />
      <Formik initialValues={initialValues} onSubmit={authenticatePassword}>
        {({}) => (
          <Form>
            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Field as={Input} id="password" name="password" type="password" />
            </FormControl>
            <Button
              type="submit"
              backgroundColor={'var(--blue-dark)'}
              color="white"
              width="full"
              mb={4}
              _hover={{
                backgroundColor: 'var(--blue-dark-hover)',
              }}
            >
              Log in
            </Button>
            <Center>
              <Button
                variant="link"
                onClick={() => resetPasswordStart(initialEmail)}
                size={'sm'}
                color={'var(--blue-dark)'}
              >
                Forgot password?
              </Button>
            </Center>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default PasswordLogin
