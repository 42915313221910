import {
  UserInfo,
  UserQueryKey,
  UserAddressesQueryKey,
  UserAddresses,
  ShareUserAddress,
  CreateUser,
  CheckEligibility,
  PostUserLogin,
} from 'api/common/routes'
import axios, { AxiosResponse } from 'axios'
import { Program } from 'context/OnboardingContext/OnboardingProvider'
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { WaitlistUser } from './useEligibilityInfo'
import { useStytch, useStytchSession } from '@stytch/react'
import { RoutePaths } from 'containers/Core/Routes'

export interface User {
  id: string
  firstName?: string
  lastName?: string
  preferredName?: string
  email: string
  waitlistUser: WaitlistUser
  phoneNumber?: string
  sourceHealthId?: string
  accountDeactivated: boolean
  messagingDeactivated: boolean
  dietician?: Provider
  medicalProvider?: Provider
  smsPreference?: { sms: boolean }
  stripeCustomer?: StripeCustomer
  termsAndConditionSigned?: Date
  discountsAppliedToUserMembership?: string[]
  accountStatus: 'ACTIVE' | 'CANCELLED' | 'PAUSED' | 'ONBOARDING'
  cancellationReason: string | null
  addresses: AddressInfo[]
}

export interface AddressInfo {
  address_line1: string
  address_line2: string
  city: string
  state: string
  zip: string
  type: AddressType
}
export enum AddressType {
  PRIMARY = 'PRIMARY',
}

interface CreateUserDto {
  email: string
  authId: string
  phoneNumber?: string
  responderUuid?: string
  variantLabel?: string
  loginOption?: 'otp_email' | 'otp_phone' | 'password'
}
export interface StripeCustomer {
  id: string
  subscriptionId: string
  product: { type: Program; nonPatientProduct: boolean }
  tier?: string
  status: string
}

export interface Provider {
  id: string
  firstName: string
  lastName: string
  email: string
  credentials: string
  sourceHealthId: string
  type: 'Medical Provider' | 'Dietician'
}

export interface UpdateUserDto {
  firstName?: string
  lastName?: string
  preferredName?: string
  email?: string
  phoneNumber?: string
  addresses?: AddressInfo[]
}

interface ErrorWithLink {
  message: string
  link?: string
  linkText?: string
}

interface UseUserInterface {
  user?: User
  userError?: unknown
  isLoading: boolean
  handleUpdateProfile: UseMutateAsyncFunction<
    AxiosResponse<void> | undefined,
    unknown,
    UpdateUserDto,
    unknown
  >
  handleAcceptTerms: UseMutateAsyncFunction<
    AxiosResponse<void> | undefined,
    unknown,
    void,
    unknown
  >
  addresses?: AddressInfo[] | undefined
  addressesError?: unknown
  shareAddressInfo?: (
    addressInfo: AddressInfo
  ) => Promise<User | undefined | null>
  createUser: (createUser: CreateUserDto) => Promise<User | undefined>
  checkUserEligibilityPreSignup: (
    email: string,
    phone: string
  ) => Promise<{
    data: WaitlistUser | null
    error: string | null | ErrorWithLink
  }>
  postLoginExecution: () => Promise<void | undefined>
}

const useUser = (): UseUserInterface => {
  const queryClient = useQueryClient()
  const { session } = useStytchSession()
  const stytch = useStytch()

  const checkUserEligibilityPreSignup = async (
    email: string,
    phone: string
  ) => {
    const secret = process.env.REACT_APP_X_API_KEY ?? ''

    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}${CheckEligibility}/${email}?phone=${phone}`,
      {
        method: 'GET',
        headers: {
          'X-API-KEY': secret,
        },
      }
    )

    if (response.status === 400) {
      return {
        data: null,
        error: {
          message:
            'There might already be an account associated with this information. Please try signing in',
          link: `${window.location.origin}${RoutePaths.LOGIN}`,
          linkText: 'here',
        },
      }
    }

    if (response.status === 404) {
      return {
        data: null,
        error:
          'Please check eligibility first at https://app.allarahealth.com/check-eligibility',
      }
    }

    const waitlistUser = await response.json()

    if (
      !waitlistUser ||
      (waitlistUser.eligibleProducts.length === 0 &&
        (!waitlistUser.dob || !waitlistUser.gender || !waitlistUser.state))
    ) {
      return {
        data: null,
        error:
          'Please check eligibility first at https://app.allarahealth.com/check-eligibility',
      }
    } else if (waitlistUser.eligibleProducts.length === 0) {
      return { data: null, error: "Hang on, you're not eligible yet." }
    }

    return { data: waitlistUser, error: null }
  }

  const createUser = async (
    createUser: CreateUserDto
  ): Promise<User | undefined> => {
    const createUserParams = {
      createUserDto: {
        email: createUser.email,
        authId: createUser.authId,
        phoneNumber: createUser.phoneNumber ?? '',
      },
      responderUuid: createUser.responderUuid ?? '',
      variantLabel: createUser.variantLabel ?? '',
      loginOption: createUser.loginOption ?? '',
    }

    const secret = process.env.REACT_APP_X_API_KEY ?? ''

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}${CreateUser}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': secret,
          },
          body: JSON.stringify(createUserParams),
        }
      )
      const data = await response.json()
      return data
    } catch (error) {
      return undefined
    }
  }

  const postLoginExecution = async () => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined

    try {
      fetch(
        `${process.env.REACT_APP_SERVER_URL}${PostUserLogin}/${session.user_id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
    } catch (error) {
      return undefined
    }
  }

  const fetchUser = async (): Promise<User | undefined> => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined

    return fetch(`${process.env.REACT_APP_SERVER_URL}${UserInfo}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then(async (res) => {
      const data = await res.json()

      if (res.ok) {
        return data
      } else {
        return undefined
      }
    })
  }

  const { data: user, error: userError, isLoading } = useQuery(
    UserQueryKey,
    fetchUser
  )

  const fetchUserAddresses = async (): Promise<AddressInfo[] | undefined> => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const session_jwt = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${UserAddresses}`, {
      headers: { Authorization: `Bearer ${session_jwt}` },
    }).then(async (res) => {
      const data = await res.json()

      if (res.ok) {
        return data
      } else {
        return undefined
      }
    })
  }

  const {
    data: addresses,
    error: addressesError,
    isLoading: areAddressesLoading,
  } = useQuery(UserAddressesQueryKey, fetchUserAddresses)

  const shareAddressInfo = async (
    addressInfo: AddressInfo
  ): Promise<User | undefined | null> => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined

    return fetch(`${process.env.REACT_APP_SERVER_URL}${ShareUserAddress}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(addressInfo),
    })
      .then(async () => {
        return addressInfo
      })
      .catch((error) => {
        console.error('Error sharing address info:', error)
        return error.message
      })
  }

  const updateProfile = async (updateUserDto: UpdateUserDto) => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_URL}${UserInfo}`,
      updateUserDto,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    return response
  }

  const mutateUpdate = useMutation(updateProfile, {
    onSettled: () => queryClient.invalidateQueries(UserQueryKey),
    onMutate: async (updateUserDto: UpdateUserDto) => {
      await queryClient.cancelQueries(UserQueryKey)

      const previousData = queryClient.getQueryData<User>(UserQueryKey)

      if (previousData) {
        queryClient.setQueryData<User>(UserQueryKey, {
          ...previousData,
          ...updateUserDto,
        })
      }

      return { previousData }
    },
  })

  const handleUpdateProfile = mutateUpdate.mutateAsync

  const acceptTermsAndConditions = async () => {
    if (!session) {
      return
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios.post(
      `${process.env.REACT_APP_SERVER_URL}${UserInfo}/accept-conditions`,
      null,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
  }

  const mutateAcceptTerms = useMutation(acceptTermsAndConditions, {
    onSettled: () => queryClient.invalidateQueries(UserQueryKey),
    onMutate: async () => {
      await queryClient.cancelQueries(UserQueryKey)

      const previousData = queryClient.getQueryData<User>(UserQueryKey)

      if (previousData) {
        queryClient.setQueryData<User>(UserQueryKey, {
          ...previousData,
          termsAndConditionSigned: new Date(),
        })
      }

      return { previousData }
    },
  })

  const handleAcceptTerms = mutateAcceptTerms.mutateAsync

  return {
    user,
    userError,
    isLoading: isLoading || areAddressesLoading,
    handleUpdateProfile,
    handleAcceptTerms,
    addresses,
    addressesError,
    shareAddressInfo,
    createUser,
    checkUserEligibilityPreSignup,
    postLoginExecution,
  }
}

export default useUser
