import {
  Appointment,
  AppointmentType,
  Slot,
  Member,
  User,
} from '@allara-health/source-health-client'
import { RoutePaths } from 'containers/Core/Routes'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import { useSourceContext } from 'context/SourceContext/SourceProvider'
import useAppointmentType from 'hooks/useAppointments/useAppointmentType'
import useSearchParams from 'hooks/useSearchParams'
import UseSourceAppointment from 'hooks/useAppointments/useSourceAppointment'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { BookingEventSource } from 'utils/amplitude.types'

export type SchedulingScreen =
  | 'appointmentTypeSelect'
  | 'location'
  | 'book'
  | 'confirm'
  | 'success'

export type AmplitudeBookingMetadata = {
  source: BookingEventSource
  patient_action_id: string | null
  patient_action_definition: string | null
}

interface SchedulingContextInterface {
  dateTime?: Date
  timeZone?: string
  provider?: User
  isReschedule?: boolean
  amplitudeBookingMetadata: AmplitudeBookingMetadata
  isLoadingApptType: boolean
  appointmentType?: AppointmentType
  appointment?: Appointment
  currentScreen: SchedulingScreen
  selectedSlot?: Slot
  participantsChanged: boolean
  setAppointmentTypeId: (appointmentTypeId: string) => void
  setDateTime: (dateTime: Date) => void
  setTimeZone: (timeZone: string) => void
  nextScreen: () => void
  previousScreen: () => void
  bookAppointment: () => Promise<Appointment | null>
  setProvider: (provider?: User) => void
  setCurrentScreen: (screen: SchedulingScreen) => void
  setSelectedSlot: (slot?: Slot) => void
  cancelAppointment: () => Promise<void>
  rescheduleAppointment: () => Promise<Appointment | null>
}

export const SchedulingContext = createContext<SchedulingContextInterface>(
  {} as SchedulingContextInterface
)

interface SchedulingProviderProps {
  appointmentId?: string
  initialAppointmentTypeId?: string
  initialScreen?: SchedulingScreen
  children: React.ReactNode
}

export const SchedulingProvider: React.FC<SchedulingProviderProps> = ({
  appointmentId,
  initialAppointmentTypeId,
  initialScreen = 'location',
  children,
}) => {
  const history = useHistory()
  const { setAlertText } = useAlert()
  const { source } = useSourceContext()
  const searchParams = useSearchParams()
  const isReschedule = useMemo(() => (appointmentId ? true : false), [
    appointmentId,
  ])
  /*
  this should only ever be called once per booking flow
  note that searchParams are excluded from the dependency array
  because they are not expected to change.
  this exists to track actions outside of the scheduling context that navigate the user into the booking flow
  */
  const amplitudeBookingMetadata = useMemo(() => {
    return {
      source:
        (searchParams.get('source') as BookingEventSource) ??
        BookingEventSource.UNKNOWN,
      patient_action_id: searchParams.get('patient_action_id'),
      patient_action_definition: searchParams.get('patient_action_definition'),
    }
  }, [])
  const [appointmentTypeId, setAppointmentTypeId] = useState(
    initialAppointmentTypeId
  )
  const {
    appointment: previousAppointment,
    isLoading: isLoadingAppt,
  } = UseSourceAppointment(appointmentId)
  const {
    appointmentType: fetchedAppointmentType,
    isLoading: isLoadingApptType,
  } = useAppointmentType(appointmentTypeId)
  const [dateTime, _setDateTime] = useState<Date | undefined>(undefined)
  const [timeZone, _setTimeZone] = useState<string | undefined>(undefined)
  const [provider, setProvider] = useState<User | undefined>(undefined)
  const [selectedSlot, setSelectedSlot] = useState<Slot | undefined>(undefined)
  const [appointment, setAppointment] = useState<Appointment | undefined>(
    undefined
  )
  const [currentScreen, setCurrentScreen] = useState<SchedulingScreen>(
    isReschedule ? 'success' : initialScreen
  )
  const [appointmentType, setAppointmentType] = useState<
    AppointmentType | undefined
  >(undefined)
  const [participantsChanged, setParticipantsChanged] = useState<boolean>(false)

  const setDateTime = (dateTime: Date) => _setDateTime(dateTime)
  const setTimeZone = (timeZone: string) => _setTimeZone(timeZone)

  const nextScreen = useCallback(() => {
    switch (currentScreen) {
      case 'appointmentTypeSelect':
        setCurrentScreen('location')
        break
      case 'location':
        setCurrentScreen('book')
        break
      case 'book':
        setCurrentScreen('confirm')
        break
      case 'confirm':
        // Note - this is intentionally left blank since
        //  it is handled in the bookAppointment and
        //  rescheduleAppointment handlers below
        break
      case 'success':
        history.push(`${RoutePaths.DASHBOARD}?appointment=${appointment?.id}`)
        break
      default:
        break
    }
  }, [currentScreen, setCurrentScreen])

  const previousScreen = useCallback(() => {
    switch (currentScreen) {
      case 'book':
        setCurrentScreen('location')
        break
      case 'confirm':
        setCurrentScreen('book')
        break
      case 'location':
        initialScreen === 'appointmentTypeSelect'
          ? setCurrentScreen('appointmentTypeSelect')
          : history.push(RoutePaths.DASHBOARD)
        break
      case 'appointmentTypeSelect':
        history.push(RoutePaths.DASHBOARD)
        break
      case 'success':
      default:
        break
    }
  }, [currentScreen, setCurrentScreen])

  const bookAppointment = useCallback(() => {
    if (!appointmentType || !timeZone || !dateTime || !provider) {
      setAlertText('Please refresh the page and try again', 'An Error Occurred')
      return Promise.resolve(null)
    }

    return source.scheduling.appointments
      .create({
        appointment_type: appointmentType.id,
        time_zone: timeZone,
        start_at: dateTime.toISOString(),
        participants: [{ participant: provider.id }],
      })
      .then((appointment) => {
        setAppointment(appointment)
        setCurrentScreen('success')
        history.push(`${RoutePaths.SCHEDULING}?appointment=${appointment.id}`)
        return appointment
      })
  }, [source, appointmentType, timeZone, dateTime, provider])

  const rescheduleAppointment = useCallback(() => {
    if (
      !appointmentType ||
      !appointment ||
      !timeZone ||
      !dateTime ||
      !provider
    ) {
      setAlertText('Please refresh the page and try again', 'An Error Occurred')
      return Promise.resolve(null)
    }

    // TODO: confirm its not identical with existing appointment?

    // Confirm the appointment types match
    const scheduledAppointmentTypeId =
      typeof appointment.appointment_type === 'string'
        ? appointment.appointment_type
        : appointment.appointment_type?.id

    if (scheduledAppointmentTypeId !== appointmentType.id) {
      setAlertText(
        'You cannot update the appointment type for an appointment. Please contact concierge for assistance',
        'Hold On'
      )
      return Promise.resolve(null)
    }

    // If new participant remove video call from appointment so a new one can be created
    // let video_call = appointment.video_call
    const currentParticipant = appointment.participants[0].participant
    const currentProviderId =
      typeof currentParticipant === 'string'
        ? currentParticipant
        : currentParticipant.id

    if (provider.id !== currentProviderId) {
      // video_call = null
      setParticipantsChanged(true)
    }

    return source.scheduling.appointments
      .update(appointment.id, {
        time_zone: timeZone,
        start_at: dateTime.toISOString(),
        duration: appointmentType.duration,
        participants: [{ participant: provider.id }],
        // video_call,
      })
      .then((appointment) => {
        setAppointment(appointment)
        setCurrentScreen('success')
        history.push(`${RoutePaths.SCHEDULING}?appointment=${appointment.id}`)
        return appointment
      })
  }, [source, appointmentType, timeZone, dateTime, provider])

  const cancelAppointment = useCallback(() => {
    if (!appointment) {
      setAlertText('Please refresh the page and try again', 'An Error Occurred')
      return Promise.resolve()
    }

    return source.scheduling.appointments
      .cancel(appointment.id, { cancellation_reason: 'patient_requested' })
      .then((canceledAppointment) => {
        setAppointment(canceledAppointment)
      })
  }, [source, appointment])

  // Set appointment and appointmentType when the previous
  // appointment is loaded
  useEffect(() => {
    if (previousAppointment && !appointment && currentScreen !== 'location') {
      setAppointment(previousAppointment)
      setAppointmentType(
        previousAppointment.appointment_type as AppointmentType
      )
      setDateTime(new Date(previousAppointment.start_at))

      if (previousAppointment.participants.length) {
        setProvider(previousAppointment.participants[0].participant as User)
      }

      const member = previousAppointment.member as Member

      if (member.time_zone) {
        setTimeZone(member.time_zone)
      }
    }
  }, [previousAppointment, appointment])

  // Set appointmentType when loaded
  useEffect(() => {
    if (fetchedAppointmentType) {
      setAppointmentType(fetchedAppointmentType)
    }
  }, [fetchedAppointmentType])

  // Handle invalid appointment type
  useEffect(() => {
    if (appointmentTypeId && !isLoadingApptType && !fetchedAppointmentType) {
      history.push(RoutePaths.DASHBOARD)
    }
  }, [appointmentTypeId, fetchedAppointmentType, isLoadingApptType])

  // Handle invalid appointment
  useEffect(() => {
    if (appointmentId && !isLoadingAppt && !previousAppointment) {
      history.push(RoutePaths.DASHBOARD)
    }
  }, [appointmentId, previousAppointment, isLoadingAppt])

  // Handle polling if no zoom link yet
  useEffect(() => {
    let interval: NodeJS.Timeout

    if (appointment && !appointment.video_call) {
      let poll = 4
      interval = setInterval(() => {
        if (poll <= 0) {
          clearInterval(interval)
        } else {
          poll--
          source.scheduling.appointments
            .retrieve(appointment.id)
            .then((appt) => {
              if (appt.video_call) {
                setAppointment(appt)
                clearInterval(interval)
              }
            })
        }
      }, 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [appointment])

  return (
    <SchedulingContext.Provider
      value={{
        dateTime,
        timeZone,
        provider,
        isReschedule,
        amplitudeBookingMetadata,
        currentScreen,
        isLoadingApptType,
        appointmentType,
        appointment,
        selectedSlot,
        participantsChanged,
        setAppointmentTypeId,
        setDateTime,
        setTimeZone,
        setProvider,
        nextScreen,
        previousScreen,
        bookAppointment,
        setCurrentScreen,
        setSelectedSlot,
        cancelAppointment,
        rescheduleAppointment,
      }}
    >
      {children}
    </SchedulingContext.Provider>
  )
}

export const useSchedulingContext = (): SchedulingContextInterface =>
  useContext(SchedulingContext)
