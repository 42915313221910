import RadioInput from 'components/RadioGroup/RadioInput'
import { memo, useState, useMemo, useEffect } from 'react'
import {
  ChakraProvider,
  Text,
  Stack,
  Link,
  Button,
  Tooltip,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import useSourceSlot from 'hooks/useAppointments/useSourceSlot'
import { Expandable, Slot, User } from '@allara-health/source-health-client'
import { endOfMonth, startOfMonth } from 'date-fns'
import { format } from 'date-fns'
import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import Calendar, {
  Chip,
  ControlHeader,
} from '../../components/Scheduling/Calendar'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import LoadingState from 'components/Spinner'
import { RoutePaths } from 'containers/Core/Routes'
import ProviderDetails from 'components/Scheduling/ProviderDetails'
import {} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import useSourcePrecheck from 'hooks/useSourcePrecheck'
import { useHistory } from 'react-router-dom'
import { ChakraPrimaryButton } from 'components/Button'
import {
  AppointmentBookAvailabilityClickEventProps,
  AppointmentBookAvailabilityShowEventProps,
  AppointmentBookSlotSelectedEventProps,
} from 'utils/amplitude.types'
import { Amplitude } from 'utils/amplitude.utils'
import withAccount, { WithAccountProps } from 'hoc/withAccount'

const BookScreen: React.FC<WithAccountProps> = ({ user }) => {
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [
    hasSentShowAvailabilityEvent,
    setHasSentShowAvailabilityEvent,
  ] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState<Slot | undefined>()
  const [selectedProvider, setSelectedProvider] = useState<User | undefined>()
  const [showMoreSlots, setShowMoreSlots] = useState(5)
  const [viewState, setViewState] = useState<
    | 'loading'
    | 'preCheckResultIsFalse'
    | 'noSlots'
    | 'slotsAvailable'
    | 'pastPlanningHorizon'
  >('loading')

  const { isOpen, onClose, onOpen } = useDisclosure()

  const history = useHistory()
  /**
   * Fetches the precheck result to determine if the user can book an appointment
   */
  const {
    result: precheckResult,
    isLoading: isPrecheckLoading,
  } = useSourcePrecheck()

  const { slots, isLoading } = useSourceSlot(
    startOfMonth(calendarDate).toISOString(),
    endOfMonth(calendarDate).toISOString()
  )

  const {
    amplitudeBookingMetadata,
    appointment,
    appointmentType,
    isReschedule,
  } = useSchedulingContext()

  const latestEnd = new Date()
  latestEnd.setDate(
    latestEnd.getDate() + (appointmentType?.planning_horizon ?? 0)
  )

  /**
   * Handles the click event for the dashboard button in the modal.
   */
  const onClickDashboardButton = () => {
    history.push(RoutePaths.DASHBOARD + `?from=scheduling`)
  }

  /**
   * Handles the click event for the contact concierge button in the modal.
   */
  const onClickContactConciergeButton = () => {
    // Go to messages view and open up a new thread with Patient Concierge
    history.push(RoutePaths.DASHBOARD_NEW_THREAD + '?sbj=8')
  }

  // Function to handle the pre-check failure logic with delay
  const handlePreCheckFailure = () => {
    const timeOutId = setTimeout(() => {
      setViewState('preCheckResultIsFalse')
      onOpen() // Open the modal after setting the state
    }, 3000) // 3-second delay
    return () => clearTimeout(timeOutId)
  }

  // Update the view state based on the loading state and the availability of slots
  useEffect(() => {
    if (precheckResult?.result === false) {
      handlePreCheckFailure()
    } else if (isPrecheckLoading || isLoading) {
      setViewState('loading')
    } else if (noSlotsAvailableOnDay()) {
      setViewState('noSlots')
    } else if (latestEnd < calendarDate) {
      setViewState('pastPlanningHorizon')
    } else {
      setViewState('slotsAvailable')
    }
  }, [calendarDate, slots, isLoading, precheckResult, isPrecheckLoading])

  const {
    currentScreen,
    timeZone,
    selectedSlot: contextSelectedSlot,
    provider,
    setSelectedSlot: setContextSelectedSlot,
    setProvider,
    setDateTime,
    nextScreen,
  } = useSchedulingContext()

  const chipState = (
    state:
      | 'active'
      | 'selected'
      | 'disabled'
      | 'disabled-with-border'
      | 'active-no-border'
  ) => {
    switch (state) {
      case 'active':
        return 'chip chip-with-border'
      case 'selected':
        return 'chip chip-picked text-white'
      case 'disabled':
        return 'chip chip-disabled'
      case 'active-no-border':
        return 'chip chip-no-border'
      case 'disabled-with-border':
        return 'chip chip-with-border chip-disabled'
      default:
        break
    }
  }

  const getMorningSlots: Slot[] = useMemo(() => {
    return (
      slots?.filter((slot) => {
        const date = utcToZonedTime(slot.start_at, timeZone ?? '')
        return (
          date.toDateString() === calendarDate.toDateString() &&
          date.getHours() >= 5 &&
          date.getHours() <= 11
        )
      }) ?? []
    )
  }, [slots, calendarDate])

  const getAfternoonSlots: Slot[] = useMemo(() => {
    return (
      slots?.filter((slot) => {
        const date = utcToZonedTime(slot.start_at, timeZone ?? '')
        return (
          date.toDateString() === calendarDate.toDateString() &&
          date.getHours() >= 12 &&
          date.getHours() <= 16
        )
      }) ?? []
    )
  }, [slots, calendarDate])

  const getEveningSlots: Slot[] = useMemo(() => {
    return (
      slots?.filter((slot) => {
        const date = utcToZonedTime(slot.start_at, timeZone ?? '')
        return (
          date.toDateString() === calendarDate.toDateString() &&
          (date.getHours() >= 17 ||
            (date.getHours() >= 0 && date.getHours() <= 4))
        )
      }) ?? []
    )
  }, [slots, calendarDate])

  const initialSlots = useMemo(
    () => [
      ...getMorningSlots.slice(0, showMoreSlots),
      ...getAfternoonSlots.slice(0, showMoreSlots),
      ...getEveningSlots.slice(0, showMoreSlots),
    ],
    [
      getMorningSlots,
      getAfternoonSlots,
      getEveningSlots,
      isLoading,
      showMoreSlots,
    ]
  )

  useEffect(() => {
    /*
    we have to be somewhat careful here because we make an amplitude call every time this runs.
    to limit the risk of accidental spamming, we only make the call if the initialSlots are not loading and there are slots available, and manage the state of the call with hasSentShowAvailabilityEvent.
    */
    if (isLoading || hasSentShowAvailabilityEvent) {
      return
    }

    const currSlot =
      initialSlots && initialSlots.length > 0 ? initialSlots[0] : undefined
    setHasSentShowAvailabilityEvent(true)
    const amplitudeEventProps: AppointmentBookAvailabilityShowEventProps = {
      appointment_id: appointment?.id ?? '',
      appointment_type_name: appointmentType?.name ?? '',
      is_reschedule: isReschedule ?? false,
      slots_date: currSlot?.start_at?.split('T')[0] ?? '',
      nearest_slot_time: currSlot?.start_at ?? '',
      available_slots_cnt: initialSlots?.length ?? 0,
      available_slot_times: initialSlots?.map((slot) => slot.start_at) ?? [],
      current_month_available_slots_cnt: slots?.length ?? 0,
      current_month_available_slot_dates_cnt: datesFromSlots.length,
      state: user.waitlistUser.state.code,
      carrier: user.waitlistUser.insuranceProvider ?? '',
      ...amplitudeBookingMetadata,
    }
    Amplitude.appointmentBookAvailabilityShow(amplitudeEventProps)
  }, [calendarDate, initialSlots?.length, isLoading])

  const showMore = (): boolean => {
    //get length of slots for today to show load more slots button or not
    const todaySlotsLength =
      getMorningSlots.length + getAfternoonSlots.length + getEveningSlots.length
    return todaySlotsLength > showMoreSlots * 3
  }

  const noSlotsAvailableOnDay = (): boolean => {
    if (calendarDate < latestEnd) {
      const todaySlotsLength =
        getMorningSlots.length +
        getAfternoonSlots.length +
        getEveningSlots.length
      return todaySlotsLength === 0
    }

    return false
  }

  const changeCalendar = (month = 0, day = 0) => {
    setCalendarDate(
      new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth() + month,
        calendarDate.getDate() + day
      )
    )
    setHasSentShowAvailabilityEvent(false)
    setSelectedProvider(undefined)
    setSelectedSlot(undefined)
    setShowMoreSlots(5)
  }

  const pickDate = (date: Date) => {
    setCalendarDate(date)
    setHasSentShowAvailabilityEvent(false)
    setSelectedProvider(undefined)
    setSelectedSlot(undefined)
    setShowMoreSlots(5)
  }

  const pickSlot = (slot: Slot) => {
    setSelectedSlot(slot)
    setSelectedProvider(undefined)
    const amplitudeEventProps: AppointmentBookAvailabilityClickEventProps = {
      appointment_id: appointment?.id ?? '',
      appointment_type_name: appointmentType?.name ?? '',
      is_reschedule: isReschedule ?? false,
      slot_time: slot.start_at,
      available_providers_cnt: slot.available.length,
      available_providers: slot.available.map(
        (provider) => (provider as User).id
      ),
      ...amplitudeBookingMetadata,
    }
    Amplitude.appointmentBookAvailabilityClick(amplitudeEventProps)
  }

  const datesFromSlots: string[] = useMemo(() => {
    // Gets unique dates from slots
    const dates = Array.from(
      new Set(
        slots?.map((slot) =>
          utcToZonedTime(slot.start_at, timeZone ?? '').toDateString()
        )
      )
    )

    if (dates.length > 0) {
      // Sets calendar date to the first available date with slots
      const firstAvailableDate = dates.find(
        (val) => new Date(val).getMonth() === calendarDate.getMonth()
      )
      setCalendarDate(new Date(firstAvailableDate ?? ''))
    }

    return dates
  }, [slots])

  useEffect(() => {
    // Automatically set provider and slot if any exist in the context
    setSelectedSlot(contextSelectedSlot)
    setSelectedProvider(provider)
    setCalendarDate(
      utcToZonedTime(
        contextSelectedSlot?.start_at ?? new Date(),
        timeZone ?? ''
      )
    )
  }, [currentScreen])

  const isSubmitButtonDisabled = useMemo(
    () => (!selectedProvider || !selectedSlot ? true : false),
    [selectedProvider, selectedSlot]
  )

  const submitButton = (
    <Button
      onClick={() => {
        const amplitudeEventProps: AppointmentBookSlotSelectedEventProps = {
          appointment_id: appointment?.id ?? '',
          appointment_type_name: appointmentType?.name ?? '',
          is_reschedule: isReschedule ?? false,
          slot_time: selectedSlot?.start_at ?? '',
          provider_id: selectedProvider?.id ?? '',
          ...amplitudeBookingMetadata,
        }
        Amplitude.appointmentBookSlotSelected(amplitudeEventProps)
        setDateTime(new Date(selectedSlot?.start_at ?? ''))
        setProvider(selectedProvider)
        setContextSelectedSlot(selectedSlot)
        setHasSentShowAvailabilityEvent(false)
        nextScreen()
      }}
      type="submit"
      backgroundColor="var(--blue-dark)"
      color="white"
      size="lg"
      isDisabled={isSubmitButtonDisabled}
      _disabled={{ pointerEvents: 'none', opacity: 0.6 }}
      _hover={{ backgroundColor: 'var(--blue-dark-hover)' }}
      rounded="3xl"
    >
      Next
      <ChevronRightIcon boxSize={6} />
    </Button>
  )

  return (
    <ChakraProvider>
      <div className="screen-container">
        <div className="appointment d-flex flex-column">
          <h1 className="ff-inter-medium fs-8">Appointment</h1>
          <p>Date & Time</p>
          <div className="d-flex  align-items-start appointment-sub">
            <div>
              <ControlHeader
                disablePrevious={calendarDate.getTime() <= new Date().getTime()}
                previous={() => {
                  changeCalendar(-1)
                }}
                next={async () => {
                  changeCalendar(1)
                }}
                title={`${format(
                  calendarDate,
                  'MMMM'
                )} ${calendarDate.getFullYear()}`}
              />
              <Calendar
                datesFromSlot={datesFromSlots}
                date={calendarDate}
                setDate={pickDate}
                slots={datesFromSlots}
              />
            </div>
            <div className="appointment-container-scheduling ml-md-5 ">
              <ControlHeader
                previous={() => {
                  changeCalendar(0, -1)
                }}
                next={() => {
                  changeCalendar(0, 1)
                }}
                title={`${format(calendarDate, 'EEEE')}, ${format(
                  calendarDate,
                  'MMMM'
                )} ${calendarDate.getDate()}`}
              />
              {(() => {
                switch (viewState) {
                  case 'loading':
                    return <LoadingState />
                  case 'preCheckResultIsFalse':
                    return (
                      <>
                        <Modal isOpen={isOpen} onClose={onClose} isCentered>
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>
                              Why don&apos;t I see any available appointments?
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                              <Text>
                                To ensure that you don&apos;t incur out of
                                network charges, our insurance team needs to
                                confirm a few details of your plan and match you
                                with participating providers. This may take up
                                to one business day.
                              </Text>
                              <Text>
                                In the meantime, if you have any questions
                                please reach out to our Patient Concierge Team.
                              </Text>
                            </ModalBody>

                            <ModalFooter justifyContent="center" gap="1rem">
                              <ChakraPrimaryButton
                                onClick={onClickContactConciergeButton}
                              >
                                Contact Concierge
                              </ChakraPrimaryButton>
                              <ChakraPrimaryButton
                                onClick={onClickDashboardButton}
                              >
                                Go to Dashboard
                              </ChakraPrimaryButton>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </>
                    )
                  case 'noSlots':
                    return (
                      <Stack justifyContent="center" mt={2} display="flex">
                        <Text fontSize="sm">No appointments available.</Text>
                        <Text fontSize="sm">
                          Please select another date to view available
                          appointments.
                        </Text>
                      </Stack>
                    )

                  case 'slotsAvailable':
                    return (
                      <>
                        <div className="d-flex flex-row gap-8 align-items-start ">
                          <div className="d-flex flex-column gap-8 align-items-center">
                            <span>MORNING</span>
                            {getMorningSlots
                              .slice(0, showMoreSlots)
                              .map((slot) => {
                                const date = new Date(slot.start_at)
                                return (
                                  <Chip
                                    onClick={() => pickSlot(slot)}
                                    key={slot.start_at}
                                    text={formatInTimeZone(
                                      date,
                                      timeZone ?? '',
                                      'h:mm	aaa'
                                    )}
                                    state={`${chipState(
                                      slot.start_at === selectedSlot?.start_at
                                        ? 'selected'
                                        : 'active'
                                    )} chip-slot`}
                                  />
                                )
                              })}
                          </div>
                          <div className="d-flex flex-column gap-8 align-items-center">
                            <span>AFTERNOON</span>
                            {getAfternoonSlots
                              .slice(0, showMoreSlots)
                              .map((slot) => {
                                const date = new Date(slot.start_at)
                                return (
                                  <Chip
                                    onClick={() => pickSlot(slot)}
                                    key={slot.start_at}
                                    text={formatInTimeZone(
                                      date,
                                      timeZone ?? '',
                                      'h:mm	aaa'
                                    )}
                                    state={`${chipState(
                                      slot.start_at === selectedSlot?.start_at
                                        ? 'selected'
                                        : 'active'
                                    )} chip-slot`}
                                  />
                                )
                              })}
                          </div>
                          <div className="d-flex flex-column gap-8 align-items-center">
                            <span>EVENING</span>
                            {getEveningSlots
                              .slice(0, showMoreSlots)
                              .map((slot) => {
                                const date = new Date(slot.start_at)
                                return (
                                  <Chip
                                    onClick={() => pickSlot(slot)}
                                    key={slot.start_at}
                                    text={formatInTimeZone(
                                      date,
                                      timeZone ?? '',
                                      'h:mm	aaa'
                                    )}
                                    state={`${chipState(
                                      slot.start_at === selectedSlot?.start_at
                                        ? 'selected'
                                        : 'active'
                                    )} chip-slot`}
                                  />
                                )
                              })}
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                          {showMore() && (
                            <div
                              className="cursor-pointer ff-inter-medium"
                              style={{}}
                              onClick={() => {
                                setShowMoreSlots(showMoreSlots + 5)
                              }}
                            >
                              Load more
                            </div>
                          )}
                        </div>
                      </>
                    )
                  case 'pastPlanningHorizon':
                    return (
                      <Stack justifyContent="center" display="flex" mt={2}>
                        <Text fontSize="sm">
                          You have reached the end of the booking window.
                        </Text>
                        <Text fontSize="sm">
                          If you were not able to find an appointment that works
                          for you, please reach out to{' '}
                          <Link
                            href={RoutePaths.DASHBOARD_NEW_THREAD + '?sbj=8'}
                            color={'var(--blue-light)'}
                          >
                            Patient Concierge
                          </Link>{' '}
                          for scheduling assistance.
                        </Text>
                      </Stack>
                    )
                  default:
                    return <LoadingState />
                }
              })()}
            </div>
            <div>
              {viewState === 'slotsAvailable' && (
                <div className="mt-4">
                  <p>Provider</p>
                  <div className="d-flex flex-wrap gap-8">
                    {!selectedSlot && (
                      <span className="fs-3 mt-n2">
                        Select a time slot to show providers
                      </span>
                    )}
                    {selectedSlot?.available.map(
                      (participant: Expandable<User>) => {
                        const provider: User = participant as User
                        return (
                          <div
                            key={provider.id}
                            onClick={() => setSelectedProvider(provider)}
                            className={`provider-card-container d-flex flex-row align-items-center ${
                              selectedProvider?.id === provider.id
                                ? 'provider-card-container-selected'
                                : ''
                            }`}
                          >
                            <RadioInput
                              controlId={provider?.id ?? ''}
                              checked={selectedProvider?.id === provider.id}
                            />
                            <ProviderDetails provider={provider} />
                          </div>
                        )
                      }
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <Box mt={4} pb={12}>
            {isSubmitButtonDisabled && (
              <Tooltip
                label="Please ensure you have selected a time slot and a medical
            provider!"
                placement="bottom-start"
              >
                <div>{submitButton}</div>
              </Tooltip>
            )}
            {!isSubmitButtonDisabled && submitButton}
          </Box>
        </div>
      </div>
    </ChakraProvider>
  )
}

export default memo(withAccount(BookScreen))
