import { CareTeamUrl } from 'containers/Dashboard/constants'
import { Appointment } from 'hooks/useAppointments/useAppointments'
import { formatName } from './provider.utils'
import { formatTime } from './time.utils'
import { RoutePaths } from 'containers/Core/Routes'
import { BookingEventSource } from 'utils/amplitude.types'
import { AmplitudeBookingMetadata } from 'context/SchedulingContext/SchedulingProvider'

export const formatAppointmentText = (
  text: string,
  appointment: Appointment
): string => {
  let ret = text

  if (text.includes('{{appointment_provider_name}}')) {
    ret = ret.replaceAll(
      '{{appointment_provider_name}}',
      formatName(appointment.provider)
    )
  }

  if (text.includes('{{appointment_provider_name_with_link}}')) {
    ret = ret.replaceAll(
      '{{appointment_provider_name_with_link}}',
      `{{${CareTeamUrl}|${formatName(appointment.provider)}}}`
    )
  }

  if (text.includes('{{appointment_date}}')) {
    const d = new Date(appointment.time)

    ret = ret.replaceAll(
      '{{appointment_date}}',
      `${d.toLocaleDateString()} ${formatTime(d)}`
    )
  }

  return ret
}

export const generateAppointmentLink = (
  appointmentTypeId: string,
  amplitudeBookingMetadata: AmplitudeBookingMetadata = {
    source: BookingEventSource.UNKNOWN,
    patient_action_id: null,
    patient_action_definition: null,
  }
): string => {
  let baseLink = `${RoutePaths.SCHEDULING}?appointmentType=${appointmentTypeId}`

  for (const [key, value] of Object.entries(amplitudeBookingMetadata)) {
    if (value) {
      baseLink += `&${key}=${value}`
    }
  }

  return baseLink
}

export const generateGenericAppointmentLink = (
  appointmentTypeId: string,
  amplitudeBookingMetadata: AmplitudeBookingMetadata = {
    source: BookingEventSource.UNKNOWN,
    patient_action_id: null,
    patient_action_definition: null,
  }
): string => {
  return `${generateAppointmentLink(
    appointmentTypeId,
    amplitudeBookingMetadata
  )}&genericAppointment=TRUE`
}
