import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from '@chakra-ui/react'

interface UnsavedChangesModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  onSave: () => void
}

const UnsavedChangesModal = ({
  isOpen,
  onClose,
  onConfirm,
  onSave,
}: UnsavedChangesModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Unsaved Changes</ModalHeader>
        <ModalBody>
          You have unsaved changes. Are you sure you want to leave this page?
        </ModalBody>
        <ModalFooter>
          <Button
            backgroundColor={'var(--blue-dark)'}
            color="white"
            _hover={{ backgroundColor: 'var(--blue-dark-hover)' }}
            mr={3}
            onClick={onSave}
          >
            Save changes
          </Button>
          <Button colorScheme="red" onClick={onConfirm} variant="outline">
            Leave
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UnsavedChangesModal
