import { ChakraPrimaryButton } from 'components/Button'
import { WithAccountProps } from 'hoc/withAccount'
import { AppointmentNote } from 'hooks/useAppointments/useAppointmentNotes'
import useAppointments from 'hooks/useAppointments/useAppointments'
import useAppointmentTypeId from 'hooks/useAppointments/useAppointmentTypeId'
import { memo } from 'react'
import { useMemo, useState } from 'react'
import { Table } from 'react-bootstrap'
import { generateAppointmentLink } from 'utils/appointments.utils'
import { isDiagnosticPatient, isInsurancePatient } from 'utils/program.utils'
import AppointmentNoteModal from './AppointmentNoteModal'
import AppointmentTableRow from './AppointmentTableRow'
import { useHistory } from 'react-router-dom'
import withAccount from 'hoc/withAccount'
import { BookingEventSource } from 'utils/amplitude.types'

const DEFAULT_NUM_SHOWN = 5

const PastAppointments: React.FC<WithAccountProps> = ({ user }) => {
  const {
    nonCancelledAppointments,
    hasBookedAtleastTwoMd,
    hasBookedRd,
  } = useAppointments()
  const { appointmentTypeId: mdAppointmentTypeId } = useAppointmentTypeId(
    'Followup MD'
  )
  const { appointmentTypeId: rdAppointmentTypeId } = useAppointmentTypeId(
    'Followup RD'
  )
  const [showNote, setShowNote] = useState<AppointmentNote | null>(null)
  const [showAllAppointments, setshowAllAppointments] = useState<boolean>(false)

  const handleshowAllAppointments = () => setshowAllAppointments(true)
  const handleShowFewer = () => setshowAllAppointments(false)
  const hideShowNoteDialog = () => setShowNote(null)
  const history = useHistory()

  const appointmentsToShow = useMemo(
    () =>
      showAllAppointments
        ? nonCancelledAppointments
        : nonCancelledAppointments.slice(0, DEFAULT_NUM_SHOWN),
    [showAllAppointments, nonCancelledAppointments]
  )

  return (
    <>
      <div className="appointment-container d-flex flex-wrap justify-content-between align-items-center">
        <p className="spaced-capital-letters mt-4">Appointments</p>
        {isInsurancePatient(user) && (
          <div className="d-flex justify-content-between align-items-center gap-2">
            {hasBookedAtleastTwoMd &&
              user.medicalProvider &&
              !isDiagnosticPatient(user) &&
              mdAppointmentTypeId && (
                <ChakraPrimaryButton
                  onClick={() =>
                    history.push(
                      generateAppointmentLink(mdAppointmentTypeId, {
                        source: BookingEventSource.MY_HEALTH_APPOINTMENTS,
                        patient_action_id: null,
                        patient_action_definition: null,
                      })
                    )
                  }
                  styleProps={{ size: 'sm' }}
                  disabled={user.accountStatus === 'CANCELLED'}
                >
                  Book a follow-up visit
                </ChakraPrimaryButton>
              )}
            {hasBookedRd && user.dietician && rdAppointmentTypeId && (
              <ChakraPrimaryButton
                onClick={() =>
                  history.push(
                    generateAppointmentLink(rdAppointmentTypeId, {
                      source: BookingEventSource.MY_HEALTH_APPOINTMENTS,
                      patient_action_id: null,
                      patient_action_definition: null,
                    })
                  )
                }
                styleProps={{ size: 'sm' }}
                disabled={user.accountStatus === 'CANCELLED'}
              >
                Book Nutrition
              </ChakraPrimaryButton>
            )}
          </div>
        )}
      </div>
      {appointmentsToShow.length ? (
        <>
          <Table borderless className="mb-2">
            <tbody>
              {appointmentsToShow.map((appointment) => (
                <AppointmentTableRow
                  key={appointment.id}
                  appointment={appointment}
                  handleSelectNote={setShowNote}
                />
              ))}
            </tbody>
          </Table>
          {showAllAppointments && (
            <p
              onClick={handleShowFewer}
              className="spaced-capital-letters btn-text fs-1 pl-2"
            >
              Show less
            </p>
          )}
          {!showAllAppointments &&
            nonCancelledAppointments.length > DEFAULT_NUM_SHOWN && (
              <p
                onClick={handleshowAllAppointments}
                className="spaced-capital-letters btn-text fs-1 pl-2"
              >
                See All
              </p>
            )}
          <AppointmentNoteModal note={showNote} onHide={hideShowNoteDialog} />
        </>
      ) : (
        <>
          <p className="mt-4">
            You currently don&apos;t have any past appointments.
          </p>
          <p className="fs-2">
            Once you have your first appointment, you&apos;ll see a record of it
            here along with a post-appointment note from your provider.
          </p>
        </>
      )}
    </>
  )
}

export default memo(withAccount(PastAppointments))
