import { RoutePaths } from 'containers/Core/Routes'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import useSearchParams from 'hooks/useSearchParams'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Redirect } from 'react-router-dom'

const OnboardingRouter: React.FC<WithAccountProps> = ({ user }) => {
  const searchParams = useSearchParams()
  const isRecheck = searchParams.get('insurance-recheck') !== null
  const isFormsortInsurance = searchParams.get('formsort_insurance') !== null
  const schedulingRedirect = searchParams.get('appointmentType')

  // We should implement fallback values for all flags
  const { enableFormsortInsurance } = useFlags()

  const validNonCommerceInsurance =
    user.waitlistUser.insurancePlan === 'NONCOMMERCE'
  const validCommerceInsurance =
    user.waitlistUser.insurancePlan === 'COMMERCE' &&
    !!user.waitlistUser.insuranceProvider

  const hasValidInsuranceInfo =
    validCommerceInsurance || validNonCommerceInsurance

  // As opposed to formsort insurance
  const shouldShowAllaraInsuranceCheck =
    !enableFormsortInsurance || (!hasValidInsuranceInfo && !isFormsortInsurance)

  let page = RoutePaths.PROGRAMS

  if (user.accountDeactivated === false) {
    if (schedulingRedirect) {
      page = RoutePaths.SCHEDULING
    } else {
      page = RoutePaths.DASHBOARD
    }
  } else if (user.accountStatus === 'CANCELLED') {
    page = RoutePaths.MANAGE_MEMBERSHIP
  } else if (isRecheck || shouldShowAllaraInsuranceCheck) {
    page = RoutePaths.HAVE_INSURANCE
  } else if (user.waitlistUser.selectedProduct) {
    page = RoutePaths.PAYMENT_PLANS
  }

  return <Redirect to={`${page}${window.location.search}`} />
}

export default withAccount(OnboardingRouter)
