import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import EmailOTP from './EmailOTP'
import PasswordLogin from './PasswordLogin'
import SMSOTP from './SMSOTP'
import * as Yup from 'yup'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Amplitude } from 'utils/amplitude.utils'

interface FormData {
  email: string
}

const EmailLogin: React.FC = () => {
  const [email, setEmail] = useState('')
  const [step, setStep] = useState<'email' | 'choice' | 'otp' | 'password'>(
    'email'
  )
  const [showSMSLogin, setShowSMSLogin] = useState(false)
  const { passwordEnabled, emailOtpEnabled, smsOtpEnabled } = useFlags()

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  })

  const handleEmailSubmit = (values: FormData) => {
    setEmail(values.email)
    setStep('choice')
  }

  const handleOTPChoice = async () => {
    Amplitude.loginOption(false, 'otp_or_password', 'otp')
    setStep('otp')
  }

  const handlePasswordChoice = () => {
    Amplitude.loginOption(false, 'otp_or_password', 'password')
    setStep('password')
  }

  if (showSMSLogin) {
    return <SMSOTP />
  }

  if (step === 'otp') {
    return <EmailOTP initialEmail={email} />
  }

  if (step === 'password') {
    return (
      <PasswordLogin initialEmail={email} onBack={() => setStep('choice')} />
    )
  }

  if (step === 'choice') {
    return (
      <>
        <Text color="gray.700" as="h5" size="lg">
          Continue with email
        </Text>
        {emailOtpEnabled && (
          <Button
            onClick={handleOTPChoice}
            backgroundColor={'var(--blue-dark)'}
            color="white"
            width="full"
            mt={6}
            _hover={{
              backgroundColor: 'var(--blue-dark-hover)',
            }}
          >
            Use one-time password
          </Button>
        )}
        {passwordEnabled && (
          <Button
            onClick={handlePasswordChoice}
            backgroundColor={'var(--blue-dark)'}
            color="white"
            width="full"
            mt={6}
            _hover={{
              backgroundColor: 'var(--blue-dark-hover)',
            }}
          >
            Use password
          </Button>
        )}
      </>
    )
  }

  return (
    <Box>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleEmailSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form>
            <Text color="gray.700" as="h5" size="lg" mb={4}>
              Log in with email
            </Text>
            <Text color="gray.500" size="sm" mb={3}>
              Please enter the email for your Allara account.
            </Text>
            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Field as={Input} id="email" name="email" type="email" />
              <ErrorMessage name="email" component={FormErrorMessage} />
            </FormControl>

            <Button
              type="submit"
              backgroundColor={'var(--blue-dark)'}
              color="white"
              width="full"
              mt={6}
              _hover={{
                backgroundColor: 'var(--blue-dark-hover)',
              }}
              mb={4}
            >
              Next
            </Button>
            {smsOtpEnabled && (
              <Button
                colorScheme={'var(--blue-dark)'}
                variant="outline"
                width="full"
                onClick={() => setShowSMSLogin(true)}
              >
                Use mobile number instead
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default EmailLogin
