/* eslint-disable react/no-children-prop */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useStytch } from '@stytch/react'
import { StytchAPIError } from '@stytch/vanilla-js'
import { ddLog } from 'utils/logs.utils'
import { useLoginRedirect } from 'hooks/useUser/useLoginRedirect'
import EmailLogin from './EmailLogin'
import NewMethod from './NewMethod'
import { formatPhoneNumber } from 'containers/Core/utils'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import validator from 'validator'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSignupFlow } from 'hooks/useUser/useSignupFlow'
import TitleWithBack from 'components/TitleWithBack'
import { Amplitude } from 'utils/amplitude.utils'

interface SMSOTPProps {
  mode?: 'login' | 'signup'
  initialPhoneNumber?: string
  email?: string
  responderUuid?: string
  variantLabel?: string
  redirectUri?: string
  onBack?: () => void
}

const SMSOTP: React.FC<SMSOTPProps> = ({
  initialPhoneNumber = '',
  mode = 'login',
  email,
  responderUuid,
  variantLabel,
  redirectUri,
  onBack,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber)
  const [methodId, setMethodId] = useState('')
  const [showVerification, setShowVerification] = useState(mode === 'signup')
  const [code, setCode] = useState('')
  const stytchClient = useStytch()
  const toast = useToast()
  const { handleSuccessfulLogin } = useLoginRedirect()
  const [showEmailLogin, setShowEmailLogin] = useState(false)
  const [showNewMethod, setShowNewMethod] = useState(false)
  const { emailOtpEnabled, passwordEnabled } = useFlags()
  const { handleSignupSuccess } = useSignupFlow()
  const hasRun = useRef(false)
  const [isLoading, setIsLoading] = useState(false)

  const sendSMSOTP = useCallback(async () => {
    setIsLoading(true)

    try {
      if (mode === 'signup') {
        const response = await stytchClient.otps.sms.loginOrCreate(
          `+1${phoneNumber}`,
          {
            expiration_minutes: 5,
          }
        )
        setMethodId(response.method_id)
        setShowVerification(true)
      } else {
        const response = await stytchClient.otps.sms.send(`+1${phoneNumber}`, {
          expiration_minutes: 5,
        })
        setMethodId(response.method_id)
        setShowVerification(true)
      }
    } catch (error) {
      if (error instanceof StytchAPIError) {
        let description = error.error_message
        Amplitude.loginErrorShow(error.error_type)

        if (error.error_type === 'phone_number_not_found') {
          setShowNewMethod(true)
          return
        } else if (error.error_type === 'invalid_phone_number') {
          description = 'Please enter a valid phone number'
        } else if (error.error_type === 'duplicate_phone_number') {
          description =
            'There was an error verifying your phone number. Please reach out to the concierge team from your dashboard for support.'
        }

        toast({
          title: 'Error sending code',
          description: description,
          status: 'error',
          duration: 5000,
        })
        ddLog('error', 'Stytch Error::SMS OTP send error', {
          errorMessage: error.error_message,
          requestId: error.request_id,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [phoneNumber, stytchClient, toast])

  useEffect(() => {
    if (!hasRun.current && initialPhoneNumber) {
      sendSMSOTP()
      hasRun.current = true
    }
  }, [sendSMSOTP, initialPhoneNumber])

  const verifyCode = useCallback(async () => {
    setIsLoading(true)

    try {
      const response = await stytchClient.otps.authenticate(code, methodId, {
        session_duration_minutes: 60,
      })

      if (response.status_code === 200) {
        if (mode === 'signup') {
          await handleSignupSuccess({
            email: email || '',
            authId: response.user_id,
            phoneNumber,
            responderUuid,
            variantLabel: variantLabel || '',
            redirectUri: redirectUri || '',
            loginOption: 'otp_phone',
          })
        } else {
          Amplitude.userLogsIn('otp_phone')
          handleSuccessfulLogin()
        }
      }
    } catch (error) {
      if (error instanceof StytchAPIError) {
        let description = error.error_message

        if (
          error.error_type === 'otp_code_not_found' ||
          error.error_type === 'unable_to_auth_otp_code'
        ) {
          description =
            'The code you entered is invalid. Please try again or resend yourself a code.'
        }

        toast({
          title: 'Error verifying code',
          description: description,
          status: 'error',
          duration: 5000,
        })

        ddLog('error', 'Stytch Error::SMS OTP verify error', {
          errorMessage: error.error_message,
          requestId: error.request_id,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [
    code,
    methodId,
    stytchClient,
    mode,
    handleSignupSuccess,
    handleSuccessfulLogin,
    toast,
  ])

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .min(10, 'Phone number must be 10 digits')
      .test('is-valid-phone', 'Must be a valid phone number', (value) => {
        if (!value) {
          return false
        }

        return validator.isMobilePhone(value)
      }),
    code: showVerification
      ? Yup.string().required('Verification code is required')
      : Yup.string(),
  })

  if (showNewMethod) {
    return <NewMethod newType="email" />
  }

  if (showEmailLogin) {
    return <EmailLogin />
  }

  const verificationText =
    mode === 'signup' ? 'Confirm your mobile number' : 'Finish logging in'

  const verificationSubText =
    mode === 'signup'
      ? `Please enter the code we sent to ${formatPhoneNumber(
          phoneNumber
        )} to finish creating your account. Note that it will expire in 5 minutes.`
      : `Please enter the code we sent to ${formatPhoneNumber(
          phoneNumber
        )} to log in. Note that it will expire in 5 minutes.`

  return (
    <Box>
      <Formik
        initialValues={{ phoneNumber: phoneNumber, code: code }}
        validationSchema={validationSchema}
        onSubmit={() => {
          if (!showVerification) {
            sendSMSOTP()
          } else {
            verifyCode()
          }
        }}
        enableReinitialize
      >
        {({ isValid, handleChange }) => (
          <Form>
            <FormControl mb={4}>
              {showVerification ? (
                <>
                  <TitleWithBack
                    title={verificationText}
                    subtitle={verificationSubText}
                    onBack={onBack}
                  />
                  <FormControl mb={4}>
                    <FormLabel>Verification Code</FormLabel>
                    <Field
                      as={Input}
                      name="code"
                      value={code}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setCode(e.target.value)
                        handleChange(e)
                      }}
                      placeholder="******"
                    />
                    <ErrorMessage name="code" component={FormErrorMessage} />
                  </FormControl>
                </>
              ) : (
                <>
                  <Text color="gray.700" as="h5" size="lg" mb={4}>
                    Log in
                  </Text>
                  <Text mb={4}>
                    Please enter the mobile phone number associated with your
                    Allara account.
                  </Text>
                  <FormLabel>Phone Number</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children="+1" />
                    <Field
                      as={Input}
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                      maxLength={10}
                      pattern="[0-9]*"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value.replace(/[^\d]/g, '')
                        setPhoneNumber(value)
                        e.target.value = value
                        handleChange(e)
                      }}
                      placeholder="Enter your phone number"
                      borderLeftRadius={0}
                    />
                  </InputGroup>
                </>
              )}
            </FormControl>
            <Button
              type="submit"
              backgroundColor={'var(--blue-dark)'}
              color="white"
              isDisabled={showVerification ? !code : !isValid}
              isLoading={isLoading}
              loadingText={showVerification ? 'Verifying' : 'Sending'}
              _hover={{
                backgroundColor: 'var(--blue-dark-hover)',
              }}
              width="full"
              mb={4}
            >
              {showVerification
                ? mode === 'signup'
                  ? 'Create account'
                  : 'Log in'
                : 'Next'}
            </Button>
            {showVerification ? (
              <Button
                colorScheme={'var(--blue-dark)'}
                variant="link"
                width="full"
                onClick={() => {
                  sendSMSOTP()
                  toast({
                    title: 'Code resent',
                    description:
                      'A new code has been sent to your phone number',
                    status: 'success',
                    duration: 10000,
                  })
                }}
              >
                Resend code
              </Button>
            ) : emailOtpEnabled || passwordEnabled ? (
              <Button
                colorScheme={'var(--blue-dark)'}
                variant="outline"
                width="full"
                onClick={() => setShowEmailLogin(true)}
              >
                Use email instead
              </Button>
            ) : (
              <></>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default SMSOTP
