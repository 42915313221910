import Loader from 'components/Loader'
import { WithAccountProps } from 'hoc/withAccount'
import useActions from 'hooks/useTimeline/useActions'
import { Fragment, memo, useMemo, useState } from 'react'
import ActionCard from './ActionCard'
import useAppointments from 'hooks/useAppointments/useAppointments'

const ComingUp: React.FC<WithAccountProps> = (props) => {
  const { isLoading, activeActions, actionDefinitions } = useActions()
  const { appointments } = useAppointments()

  const [maxNumberShown, setMaxNumberShown] = useState<number>(3)

  const handleShowMore = () => setMaxNumberShown((n) => n + 3)
  const handleShowLess = () => setMaxNumberShown((n) => n - 3)

  const actionsToShow = useMemo(() => activeActions.slice(0, maxNumberShown), [
    activeActions,
    maxNumberShown,
  ])

  return (
    <div>
      <p className="h1 py-2 fs-8">To Do</p>
      {!isLoading &&
        activeActions.length >= 1 &&
        actionsToShow.map((action) => {
          const actionDefinition = actionDefinitions.find(
            (a) => a.id === action.definition
          )

          if (actionDefinition) {
            return (
              <ActionCard
                key={action.id}
                action={action}
                actionDefinition={actionDefinition}
                {...props}
                appointments={appointments}
              />
            )
          } else {
            return <Fragment key={action.id}></Fragment>
          }
        })}
      {!isLoading && activeActions.length >= 1 && (
        <div className="d-flex justify-content-between">
          <p className="show-more-less-text" onClick={handleShowLess}>
            {maxNumberShown === 3 ? '' : 'Show Less'}
          </p>
          <p className="show-more-less-text" onClick={handleShowMore}>
            {activeActions.length > maxNumberShown ? 'Show More' : ''}
          </p>
        </div>
      )}
      {!isLoading && activeActions.length === 0 && (
        <div>
          <p className="h2 py-1 fs-4">You&apos;re all caught up!</p>
          <p>
            You&apos;ve completed all of your upcoming tasks. When you have new
            tasks, you&apos;ll see them here.
          </p>
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  )
}

export default memo(ComingUp)
