import React from 'react'
import { Box, Button } from '@chakra-ui/react'
import TitleWithBack from 'components/TitleWithBack'
import { Amplitude } from 'utils/amplitude.utils'

interface EmailMethodChoiceProps {
  onOTPChoice: () => void
  onPasswordChoice: () => void
  onBack: () => void
  showOTPOption: boolean
  showPasswordOption: boolean
}

const EmailMethodChoice: React.FC<EmailMethodChoiceProps> = ({
  onOTPChoice,
  onPasswordChoice,
  onBack,
  showOTPOption,
  showPasswordOption,
}) => {
  return (
    <Box>
      <TitleWithBack
        title="Continue with email"
        subtitle="Choose how you'd like to verify your email."
        onBack={onBack}
      />

      {showOTPOption && (
        <Button
          onClick={() => {
            Amplitude.loginOption(true, 'otp_or_password', 'otp')
            onOTPChoice()
          }}
          backgroundColor={'var(--blue-dark)'}
          color="white"
          width="full"
          mb={showPasswordOption ? 4 : 0}
          _hover={{
            backgroundColor: 'var(--blue-dark-hover)',
          }}
        >
          Use one-time password
        </Button>
      )}

      {showPasswordOption && (
        <Button
          onClick={() => {
            Amplitude.loginOption(true, 'otp_or_password', 'password')
            onPasswordChoice()
          }}
          colorScheme={'var(--blue-dark)'}
          width="full"
          variant="outline"
        >
          Set a password
        </Button>
      )}
    </Box>
  )
}

export default EmailMethodChoice
