import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  ChakraProvider,
  Flex,
  Center,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useStytch } from '@stytch/react'
import { StytchAPIError } from '@stytch/vanilla-js'
import { ddLog } from 'utils/logs.utils'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { AxiosError } from 'axios'
import useUser from 'hooks/useUser/useUser'

const VerifyEmail: React.FC = () => {
  const [methodId, setMethodId] = useState<string>('')
  const [showVerification, setShowVerification] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [areButtonsDisabled, setAreButtonsDisabled] = useState<boolean>(false)
  const stytchClient = useStytch()
  const toast = useToast()
  const { handleUpdateProfile } = useUser()

  const handleSkip = () => {
    setAreButtonsDisabled(true)

    try {
      const redirectUrl = sessionStorage.getItem('postVerificationRedirect')

      if (redirectUrl) {
        sessionStorage.removeItem('postVerificationRedirect')
        window.location.href = redirectUrl
      } else {
        window.location.href = window.location.origin
      }
    } catch (error) {
      // Fallback if sessionStorage is unavailable
      window.location.href = window.location.origin
    } finally {
      setAreButtonsDisabled(false)
    }
  }

  const sendEmailOTP = useCallback(
    async (emailToVerify: string) => {
      setIsSubmitting(true)
      setAreButtonsDisabled(true)

      try {
        const response = await stytchClient.otps.email.send(emailToVerify, {
          expiration_minutes: 5,
        })
        setMethodId(response.method_id)
        setShowVerification(true)
      } catch (error) {
        if (error instanceof StytchAPIError) {
          let description = error.error_message

          if (error.error_type === 'invalid_email') {
            description = 'Please enter a valid email address'
          } else if (error.error_type === 'duplicate_email') {
            description =
              'There was an error verifying your email. Please try again.'
          }

          toast({
            title: 'Error sending code',
            description,
            status: 'error',
            duration: 5000,
          })
          ddLog('error', 'Stytch Error::phone OTP send error', {
            errorMessage: error.error_message,
            requestId: error.request_id,
          })
        }
      } finally {
        setIsSubmitting(false)
        setAreButtonsDisabled(false)
      }
    },
    [stytchClient, toast]
  )

  const verifyCode = useCallback(
    async (emailToVerify: string, code: string) => {
      setIsSubmitting(true)
      setAreButtonsDisabled(true)

      try {
        const response = await stytchClient.otps.authenticate(code, methodId, {
          session_duration_minutes: 60,
        })

        if (response.status_code === 200) {
          // Update the user's email in our backend
          try {
            await handleUpdateProfile({
              email: emailToVerify,
            })
          } catch (error) {
            const typedErr = error as AxiosError
            let errorMessage =
              'Your email was verified but we could not update your profile. Please try again later.'

            if (typedErr.response?.status === 409) {
              errorMessage = `Cannot update email to ${emailToVerify} because another account exists under that email. Please contact concierge@allarahealth.com to fix this issue.`
            }

            toast({
              title: 'Error updating profile',
              description: errorMessage,
              status: 'error',
              duration: 5000,
            })
          } finally {
            // After successful update, proceed with redirect
            const redirectUrl = sessionStorage.getItem(
              'postVerificationRedirect'
            )
            window.location.href = redirectUrl || window.location.origin
            sessionStorage.removeItem('postVerificationRedirect')
          }
        }
      } catch (error) {
        if (error instanceof StytchAPIError) {
          let description = error.error_message

          if (
            error.error_type === 'otp_code_not_found' ||
            error.error_type === 'unable_to_auth_otp_code'
          ) {
            description =
              'The code you entered is invalid. Please try again or resend yourself a code.'
          }

          toast({
            title: 'Error verifying code',
            description: description,
            status: 'error',
            duration: 5000,
          })
          ddLog('error', 'Stytch Error::phone OTP verify error', {
            errorMessage: error.error_message,
            requestId: error.request_id,
          })
        }
      } finally {
        setIsSubmitting(false)
        setAreButtonsDisabled(false)
      }
    },
    [methodId, stytchClient, toast, handleUpdateProfile]
  )

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    code: Yup.string().when('showVerification', {
      is: true,
      then: Yup.string().required('Verification code is required'),
    }),
  })

  return (
    <ChakraProvider>
      <Flex
        justify="center"
        align="center"
        height="100vh"
        backgroundColor="var(--gray-extra-light)"
      >
        <Box bg="white" p={8} borderRadius="lg" boxShadow="lg" width="md">
          <Formik
            initialValues={{ email: '', code: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (!showVerification) {
                sendEmailOTP(values.email)
              } else {
                verifyCode(values.email, values.code)
              }
            }}
          >
            {({ isValid, dirty, values }) => (
              <Form>
                <Box>
                  <Text color="gray.700" as="h5" size="lg" mb={4}>
                    {showVerification ? 'Log in' : 'Verify email'}
                  </Text>

                  {showVerification ? (
                    <>
                      <Text mb={4}>
                        Please enter the code we sent to {values.email} to
                        finish logging into your account.
                      </Text>
                      <FormControl mb={4}>
                        <FormLabel>Verification Code</FormLabel>
                        <Field
                          as={Input}
                          name="code"
                          placeholder="Enter code"
                        />
                        <ErrorMessage
                          name="code"
                          component={FormErrorMessage}
                        />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <Text color="gray.500" size="sm" mb={3}>
                        If you&apos;d like to sign in with your email address in
                        the future, please enter it here, and we&apos;ll send
                        you a one-time code to enter.
                      </Text>
                      <FormControl mb={4} isRequired>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Field
                          as={Input}
                          id="email"
                          name="email"
                          type="email"
                        />
                        <ErrorMessage
                          name="email"
                          component={FormErrorMessage}
                        />
                      </FormControl>
                    </>
                  )}

                  <Button
                    type="submit"
                    backgroundColor={'var(--blue-dark)'}
                    color="white"
                    width="full"
                    isDisabled={!(isValid && dirty) || areButtonsDisabled}
                    isLoading={isSubmitting}
                    mt={6}
                    _hover={{
                      backgroundColor: 'var(--blue-dark-hover)',
                    }}
                    mb={4}
                  >
                    {showVerification ? 'Log in' : 'Next'}
                  </Button>

                  {showVerification ? (
                    <>
                      <Button
                        variant="outline"
                        onClick={handleSkip}
                        width="full"
                        mb={4}
                        isDisabled={areButtonsDisabled}
                      >
                        Skip verification
                      </Button>
                      <Center>
                        <Button
                          colorScheme={'var(--blue-dark)'}
                          variant="link"
                          width="full"
                          onClick={() => {
                            sendEmailOTP(values.email)
                            toast({
                              title: 'Code resent',
                              description:
                                'A new code has been sent to your email.',
                              status: 'success',
                              duration: 10000,
                            })
                          }}
                          isDisabled={areButtonsDisabled}
                        >
                          Resend code
                        </Button>
                      </Center>
                    </>
                  ) : (
                    <Center>
                      <Button
                        variant="link"
                        onClick={handleSkip}
                        isDisabled={areButtonsDisabled}
                      >
                        Skip
                      </Button>
                    </Center>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>
    </ChakraProvider>
  )
}

export default VerifyEmail
