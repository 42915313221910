import { ButtonLink, Target } from 'containers/Dashboard/Dashboard/Card'
import { addHours, subHours } from 'date-fns'
import { Action, ActionDefinition } from 'hooks/useTimeline/useActions'
import { User } from 'hooks/useUser/useUser'
import { generateAppointmentLink } from './appointments.utils'
import { formatTime } from './time.utils'
import { BookingEventSource } from './amplitude.types'

const joinNowShowTime = addHours(new Date(), 1)

export function formatActionText(
  text: string,
  user: User,
  action?: Action
): string {
  let ret = text

  if (text.includes('{{dietician_name}}')) {
    const dieticianName = user.dietician?.firstName ?? 'an Allara Dietitian'
    ret = ret.replaceAll('{{dietician_name}}', dieticianName)
  }

  if (text.includes('{{provider_name}}')) {
    const providerName = user.medicalProvider
      ? `${user.medicalProvider.firstName} ${user.medicalProvider.lastName} (${user.medicalProvider.credentials})`
      : 'an Allara Medical Provider'
    ret = ret.replaceAll('{{provider_name}}', providerName)
  }

  if (text.includes('{{appointment_date}}')) {
    const actionDate = action?.properties?.date

    let appointmentDateText = 'unknown'

    if (actionDate) {
      const appointmentDate = new Date(actionDate)
      appointmentDateText = `${appointmentDate.toLocaleDateString()} at ${formatTime(
        appointmentDate
      )}`
    }

    ret = ret.replaceAll('{{appointment_date}}', appointmentDateText)
  }

  return ret
}

export function handleAction(
  action: Action,
  complete: (actionId: string) => void
): void {
  switch (action.definition) {
    case 'drafting-order':
      complete(action.id)
      break
    default:
      break
  }
}

export function getActionLink(
  action: Action,
  actionDefinition: ActionDefinition,
  user: User,
  completed?: boolean
): ButtonLink | undefined {
  let target: Target = '_blank'
  let link: string | undefined = undefined
  let allow = true

  if (actionDefinition.buttonLink) {
    link = actionDefinition.buttonLink
  } else if (actionDefinition.type === 'book-appt' && action.properties) {
    allow = completed ? false : true
    target = '_self'
    link = generateAppointmentLink(action.properties.typeId ?? '', {
      source: BookingEventSource.DASHBOARD_ACTION,
      patient_action_id: action.id,
      patient_action_definition: actionDefinition.id,
    })
  } else if (actionDefinition.type === 'join-now') {
    allow = completed ? false : true
    link = action.properties?.videoCallLink ?? undefined
  } else if (actionDefinition.type === 'formsort') {
    if (actionDefinition.id == 'monthly-checkin') {
      const baseUrl = process.env.REACT_APP_MONTHLY_CHECKIN_URL
      link = `${baseUrl}${baseUrl?.includes('?') ? '&' : '?'}jwt=${
        action.properties?.jwt
      }`
    }
  } else if (actionDefinition.type === 'content') {
    // Content links are handled in the Content Card component
    link = undefined
  } else if (actionDefinition.type === 'form') {
    // Form links are handled in the Card Form component
    link = undefined
  }

  return allow && link ? { link, target } : undefined
}

export function getDisableButton(
  action: Action,
  actionDefinition: ActionDefinition,
  completed?: boolean
): boolean {
  if (completed) {
    return actionDefinition.buttonLink === undefined
  } else if (actionDefinition.type === 'join-now') {
    return new Date(action.properties?.date ?? 0) > joinNowShowTime
  } else {
    return false
  }
}

export function getDisplayButton(
  disableButton: boolean,
  actionDefinition: ActionDefinition,
  completed?: boolean
): boolean {
  if (completed) {
    return !disableButton
  } else if (actionDefinition.type === 'form') {
    return false
  } else {
    return true
  }
}

export function getTooltip(
  action: Action,
  actionDefinition: ActionDefinition
): string | undefined {
  if (actionDefinition.type === 'join-now' && action.properties?.date) {
    const appointmentDate = new Date(action.properties.date)

    if (appointmentDate > joinNowShowTime) {
      const joinDate = subHours(appointmentDate, 1)
      return `This action will become available at ${joinDate.toLocaleTimeString()} on ${joinDate.toLocaleDateString()} (1 hour prior to your appointment scheduled time)`
    }
  }

  return undefined
}
