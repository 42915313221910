import React, { useState } from 'react'
import { RoutePaths } from 'containers/Core/Routes'
import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Flex,
  Text,
} from '@chakra-ui/react'
import AppLogo from 'components/AppLogo'
import SMSOTP from './SMSOTP'
import { useFlags } from 'launchdarkly-react-client-sdk'
import EmailLogin from './EmailLogin'
import { Amplitude } from 'utils/amplitude.utils'

const Login: React.FC = () => {
  const [loginMethod, setLoginMethod] = useState<
    'password' | 'email' | 'sms' | null
  >(null)
  const { passwordEnabled, emailOtpEnabled, smsOtpEnabled } = useFlags()

  const renderLoginMethod = () => {
    switch (loginMethod) {
      case 'email':
        return <EmailLogin />
      case 'sms':
        return <SMSOTP />
      default:
        return (
          <>
            <Center mb={50}>
              <AppLogo color={'var(--blue-bright)'} />
            </Center>
            <Text color="gray.700" as="h5" size="lg" mb={3}>
              Welcome back!
            </Text>
            <Text color="gray.500" size="sm" mb={3}>
              How would you like to log in?
            </Text>
            <Flex direction="column" gap={4}>
              {(emailOtpEnabled || passwordEnabled) && (
                <Button
                  onClick={() => {
                    Amplitude.loginOption(false, 'email_or_phone', 'email')
                    setLoginMethod('email')
                  }}
                  backgroundColor={'var(--blue-dark)'}
                  color="white"
                  _hover={{
                    backgroundColor: 'var(--blue-dark-hover)',
                  }}
                >
                  Log in with email
                </Button>
              )}
              {smsOtpEnabled && (
                <Button
                  onClick={() => {
                    Amplitude.loginOption(false, 'email_or_phone', 'phone')
                    setLoginMethod('sms')
                  }}
                  backgroundColor={'var(--blue-dark)'}
                  color="white"
                  _hover={{
                    backgroundColor: 'var(--blue-dark-hover)',
                  }}
                >
                  Log in with mobile number
                </Button>
              )}
              <Center>
                <Text fontSize="sm" display="inline">
                  Don&apos;t have an account?{' '}
                  <Button
                    variant="link"
                    onClick={() =>
                      (window.location.href =
                        window.location.origin + RoutePaths.CHECK_ELIGIBILITY)
                    }
                    display="inline"
                    height="auto"
                    size="sm"
                    minWidth="auto"
                    padding={0}
                    marginInlineStart={0}
                    verticalAlign="baseline"
                  >
                    See if you&apos;re eligible
                  </Button>
                </Text>
              </Center>
            </Flex>
          </>
        )
    }
  }

  return (
    <ChakraProvider>
      <Flex
        justify="center"
        align="center"
        height="100vh"
        backgroundColor="var(--gray-extra-light)"
      >
        <Box bg="white" p={8} borderRadius="lg" boxShadow="lg" width="sm">
          {renderLoginMethod()}
        </Box>
      </Flex>
    </ChakraProvider>
  )
}

export default Login
