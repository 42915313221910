export enum BookingEventSource {
  DASHBOARD_ACTION = 'dashboard_action',
  EMAIL = 'email',
  APP_NAVIGATION = 'app_navigation',
  INSURANCE_USER_ONBOARDING = 'onboarding',
  MY_HEALTH_APPOINTMENTS = 'my_health_appointments',
  UNKNOWN = 'unknown',
  RESCHEDULE = 'reschedule',
}

export type GenericAppointmentEventProps = {
  appointment_type_name: string // e.g. 'Initial Medical Visit'
  patient_action_id: string | null // null if we don't know/have the action that drove the patient's behavior
  patient_action_definition: string | null // same as above
  source: BookingEventSource
}

export type NetNewAppointmentEventProps = GenericAppointmentEventProps & {
  appointment_id: null
  appointment_time: null
  is_reschedule: false
}

export type ExistingAppointmentEventProps = GenericAppointmentEventProps & {
  appointment_id: string
  appointment_time: string
  is_reschedule: boolean // false if the user just booked the appointment
}

export type NewOrExistingAppointmentEventProps =
  | NetNewAppointmentEventProps
  | ExistingAppointmentEventProps

export type AppointmentBookStartEventProps = Omit<
  NewOrExistingAppointmentEventProps,
  'appointment_time'
>

export type AppointmentBookStateSelectedEventProps = Omit<
  NewOrExistingAppointmentEventProps,
  'appointment_time'
> & {
  state_selected: string // e.g. 'CA'
  state_on_file: string // e.g. 'CA'
  is_valid_selection: boolean
}

export type AppointmentBookAvailabilityShowEventProps = Omit<
  NewOrExistingAppointmentEventProps,
  'appointment_time'
> & {
  nearest_slot_time: string
  slots_date: string
  available_slots_cnt: number
  current_month_available_slots_cnt: number
  current_month_available_slot_dates_cnt: number
  available_slot_times: string[]
  state: string
  carrier: string
}

export type AppointmentBookAvailabilityClickEventProps = Omit<
  NewOrExistingAppointmentEventProps,
  'appointment_time'
> & {
  slot_time: string
  available_providers_cnt: number
  available_providers: string[]
}

export type AppointmentBookSlotSelectedEventProps = Omit<
  NewOrExistingAppointmentEventProps,
  'appointment_time'
> & {
  slot_time: string
  provider_id: string
}

export type AppointmentModifyEventProps = Omit<
  ExistingAppointmentEventProps,
  'is_reschedule' | 'patient_action_id' | 'patient_action_definition' | 'source'
>

export type AppointmentJoinStartEventProps = Omit<
  ExistingAppointmentEventProps,
  'is_reschedule'
>
