import React, { useState, useCallback } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  useToast,
  Flex,
  chakra,
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useStytch } from '@stytch/react'
import { StytchAPIError } from '@stytch/vanilla-js'
import { ddLog } from 'utils/logs.utils'
import PasswordInput from 'components/PasswordInput'
import { useSignupFlow } from 'hooks/useUser/useSignupFlow'
import TitleWithBack from 'components/TitleWithBack'

interface PasswordSignupProps {
  email: string
  phoneNumber: string
  redirectUri: string
  responderUuid: string
  variantLabel: string
  onBack: () => void
}

const PasswordRequirements: React.FC<{ password: string }> = ({ password }) => {
  const requirements = [
    { label: '8 characters', isValid: password.length >= 8 },
    { label: 'Uppercase letter', isValid: /[A-Z]/.test(password) },
    { label: 'Lowercase letter', isValid: /[a-z]/.test(password) },
    {
      label: 'Special character',
      isValid: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
    { label: 'Number', isValid: /\d/.test(password) },
  ]

  return (
    <Box mt={2} mb={4}>
      <Flex>
        <Box flex="1">
          {requirements.slice(0, 3).map((req, index) => (
            <Flex key={index} alignItems="center" mb={2}>
              <chakra.span mr={2} color={req.isValid ? 'green.500' : 'red.500'}>
                {req.isValid ? <CheckIcon /> : <CloseIcon />}
              </chakra.span>
              <Text
                color={req.isValid ? 'green.500' : 'red.500'}
                fontSize="sm"
                mb={0}
              >
                {req.label}
              </Text>
            </Flex>
          ))}
        </Box>
        <Box flex="1">
          {requirements.slice(3).map((req, index) => (
            <Flex key={index} alignItems="center" mb={2}>
              <chakra.span mr={2} color={req.isValid ? 'green.500' : 'red.500'}>
                {req.isValid ? <CheckIcon /> : <CloseIcon />}
              </chakra.span>
              <Text
                color={req.isValid ? 'green.500' : 'red.500'}
                fontSize="sm"
                mb={0}
              >
                {req.label}
              </Text>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Box>
  )
}

const PasswordSignup: React.FC<PasswordSignupProps> = ({
  email,
  phoneNumber,
  redirectUri,
  responderUuid,
  variantLabel,
  onBack,
}) => {
  const [password, setPassword] = useState('')
  const stytchClient = useStytch()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const { handleSignupSuccess } = useSignupFlow()

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        'Password must contain at least one special character'
      )
      .matches(/\d/, 'Password must contain at least one number')
      .required('Password is required'),
  })

  const handleSubmit = useCallback(
    async (values: { password: string }) => {
      setIsLoading(true)
      const params = {
        email,
        password: values.password,
        trusted_metadata: {
          phoneNumber,
        },
        session_duration_minutes: 60,
      }

      try {
        const stytchUser = await stytchClient.passwords.create(params)
        const success = await handleSignupSuccess({
          email: stytchUser.user.emails[0].email,
          authId: stytchUser.user_id,
          phoneNumber,
          responderUuid,
          variantLabel,
          redirectUri,
          loginOption: 'password',
        })

        if (!success) {
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)

        if (error instanceof StytchAPIError) {
          let description = 'There was an error creating your account.'

          if (error.error_type === 'breached_password') {
            description =
              'Password is too common. Please try a different password.'
          }

          toast({
            title: 'Error',
            description,
            status: 'error',
            duration: 5000,
          })

          ddLog('error', 'Stytch Error::create password error', {
            errorMessage: error.error_message,
            requestId: error.request_id,
          })
        }
      }
    },
    [
      email,
      phoneNumber,
      stytchClient,
      handleSignupSuccess,
      redirectUri,
      responderUuid,
      variantLabel,
      toast,
    ]
  )

  return (
    <Box>
      <TitleWithBack title="Set your password" onBack={onBack} />
      <Formik
        initialValues={{ password: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>
              <PasswordInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const newPassword = e.target.value
                  setPassword(newPassword)
                  setFieldValue('password', newPassword)
                }}
              />
              <ErrorMessage name="password" component={FormErrorMessage} />
              <PasswordRequirements password={password} />
            </FormControl>

            <Button
              type="submit"
              backgroundColor={'var(--blue-dark)'}
              color="white"
              width="full"
              isLoading={isLoading}
              loadingText="Creating account"
              _hover={{
                backgroundColor: 'var(--blue-dark-hover)',
              }}
            >
              Create account
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default PasswordSignup
