/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import { RoutePaths } from 'containers/Core/Routes'
import { Box, Button, Center, Text } from '@chakra-ui/react'
import SMSOTP from './SMSOTP'
import EmailLogin from './EmailLogin'
import { Amplitude } from 'utils/amplitude.utils'

const NewMethod: React.FC<{ newType: 'email' | 'sms' }> = ({ newType }) => {
  const [showNewMethod, setShowNewMethod] = useState(false)

  if (showNewMethod) {
    return newType === 'email' ? <EmailLogin /> : <SMSOTP />
  }

  const currentMethodText = newType === 'email' ? 'mobile number' : 'email'
  const newMethodText = newType === 'email' ? 'email' : 'mobile number'
  return (
    <Box>
      <Text color="gray.700" as="h5" size="lg">
        Please try a different method
      </Text>
      <Text color="gray.500" size="sm" mb={3}>
        It looks like we haven&apos;t saved your {currentMethodText}. Please try
        signing in with your {newMethodText}.
      </Text>
      <Button
        onClick={() => {
          Amplitude.loginOption(
            false,
            'email_or_phone',
            newType == 'email' ? 'email' : 'phone'
          )
          setShowNewMethod(true)
        }}
        backgroundColor={'var(--blue-dark)'}
        color="white"
        _hover={{
          backgroundColor: 'var(--blue-dark-hover)',
        }}
        width="full"
        mb={4}
      >
        Log in with {newMethodText}
      </Button>
      <Center>
        <Text fontSize="md" display="inline">
          Don&apos;t have an account?{' '}
          <Button
            variant="link"
            onClick={() =>
              (window.location.href =
                window.location.origin + RoutePaths.CHECK_ELIGIBILITY)
            }
            display="inline"
            height="auto"
            size="md"
            minWidth="auto"
            padding={0}
            marginInlineStart={0}
            verticalAlign="baseline"
          >
            See if you&apos;re eligible
          </Button>
        </Text>
      </Center>
    </Box>
  )
}

export default NewMethod
