import { useState, useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Input,
  Button,
  Text,
  useToast,
  FormLabel,
  FormControl,
} from '@chakra-ui/react'
import { useStytch } from '@stytch/react'

interface VerificationModalProps {
  isOpen: boolean
  onClose: () => void
  method: 'sms' | 'email'
  onSuccess: () => void
}

const VerificationModal = ({
  isOpen,
  onClose,
  method,
  onSuccess,
}: Omit<VerificationModalProps, 'email' | 'phoneNumber'>) => {
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [contactInfo, setContactInfo] = useState('')
  const [methodId, setMethodId] = useState('')
  const [codeSent, setCodeSent] = useState(false)
  const stytchClient = useStytch()
  const toast = useToast()

  const sendCode = useCallback(async () => {
    if (!contactInfo) {
      toast({
        title: `Please enter your ${
          method === 'email' ? 'email address' : 'phone number'
        }`,
        status: 'error',
        duration: 5000,
      })
      return
    }

    try {
      setLoading(true)

      if (method === 'email') {
        const response = await stytchClient.otps.email.send(contactInfo, {
          expiration_minutes: 5,
        })
        setMethodId(response.method_id)
      } else {
        const response = await stytchClient.otps.sms.send(`+1${contactInfo}`, {
          expiration_minutes: 5,
        })
        setMethodId(response.method_id)
      }

      setCodeSent(true)
    } catch (error) {
      toast({
        title: 'Error sending verification code',
        status: 'error',
        duration: 5000,
      })
    } finally {
      setLoading(false)
    }
  }, [method, contactInfo])

  const verifyCode = useCallback(async () => {
    try {
      setLoading(true)
      await stytchClient.otps.authenticate(code, methodId, {
        session_duration_minutes: 60,
      })
      onSuccess()
    } catch (error) {
      toast({
        title: 'Invalid verification code',
        status: 'error',
        duration: 5000,
      })
    } finally {
      setLoading(false)
    }
  }, [code, methodId])

  const handleClose = () => {
    setCode('')
    setContactInfo('')
    setCodeSent(false)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb={0}>
          Verify your {method === 'email' ? 'email' : 'phone number'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} pb={4}>
            <Text mb={0}>
              {codeSent
                ? `Please enter the one-time code we sent to verify your ${
                    method === 'email' ? 'email.' : 'phone number.'
                  }`
                : `To make edits to your account details, please first verify your ${
                    method === 'email' ? 'email.' : 'phone number.'
                  }`}
            </Text>

            {!codeSent && (
              <FormControl isRequired>
                <FormLabel>
                  {method === 'email' ? 'Email' : 'Phone Number'}
                </FormLabel>
                <Input
                  value={contactInfo}
                  onChange={(e) => setContactInfo(e.target.value)}
                />
              </FormControl>
            )}

            {!codeSent ? (
              <Button
                onClick={sendCode}
                isLoading={loading}
                backgroundColor={'var(--blue-dark)'}
                color={'white'}
                _hover={{
                  backgroundColor: 'var(--blue-dark-hover)',
                }}
                width={'100%'}
              >
                Send Verification Code
              </Button>
            ) : (
              <>
                <FormControl isRequired>
                  <FormLabel>Verification Code</FormLabel>
                  <Input
                    placeholder="******"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </FormControl>
                <Button
                  onClick={verifyCode}
                  isLoading={loading}
                  backgroundColor={'var(--blue-dark)'}
                  color={'white'}
                  _hover={{
                    backgroundColor: 'var(--blue-dark-hover)',
                  }}
                  width={'100%'}
                >
                  Verify
                </Button>
                <Button variant="link" onClick={sendCode}>
                  Resend Code
                </Button>
              </>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default VerificationModal
