import { ChakraPrimaryButton } from 'components/Button'
import { Fragment, ReactNode } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import './styles.scss'

export type Target = '_blank' | '_self'

export type ButtonLink = {
  link: string
  target: Target
}

interface DashboardCardProps {
  icon: string
  title: string
  children?: ReactNode
  description: string
  buttonLink?: ButtonLink
  buttonText: string
  buttonTooltip?: string
  displayButton: boolean
  disableButton: boolean
  handleClick?: () => void
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  icon,
  title,
  children,
  description,
  buttonLink,
  buttonText,
  buttonTooltip,
  displayButton,
  disableButton,
  handleClick,
}) => {
  const onClick = () => {
    if (handleClick) {
      handleClick()
    }

    if (buttonLink?.link) {
      window.open(buttonLink?.link, buttonLink?.target ?? '_blank')
    }
  }

  const actionButton = (
    <ChakraPrimaryButton onClick={onClick} disabled={disableButton}>
      {buttonText}
    </ChakraPrimaryButton>
  )

  const renderLink = (text: string) => {
    const [preLinkText, remainder1] = text.split('{{')
    const [link, remainder2] = remainder1.split('|')
    const [linkText, remainder] = remainder2.split('}}')

    return (
      <>
        {preLinkText}
        <a href={link} target="_blank" rel="noreferrer">
          <span className="link">{linkText}</span>
        </a>
        {remainder}
      </>
    )
  }

  const renderCardDescription = () => {
    return description.split('\\n').map((line, i) => (
      <Fragment key={i}>
        {line.includes('{{') && line.includes('}}') ? renderLink(line) : line}
        <br />
      </Fragment>
    ))
  }

  return (
    <div className="dashboard-card mb-4 p-3">
      <div className="d-inline-flex align-items-center mb-3">
        <div className="dashboard-card-icon-container">
          <img src={icon} className="dashboard-card-icon" />
        </div>
        <div>
          <p className="fs-7 mb-0">{title}</p>
        </div>
      </div>
      <p>{renderCardDescription()}</p>
      {children}
      {displayButton && (
        <div className="d-flex justify-content-flex-start">
          {buttonTooltip && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tool-tip">{buttonTooltip}</Tooltip>}
            >
              <span>{actionButton}</span>
            </OverlayTrigger>
          )}
          {!buttonTooltip && actionButton}
        </div>
      )}
    </div>
  )
}

export default DashboardCard
